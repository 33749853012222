import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'block',
    width: '100%',
    minHeight: '100vh',
    background: '#fbf8f3 url(/media/bg.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '.6rem 2rem',
    [theme.breakpoints.down('xs')]: {
      padding: '.6rem',
    },
  },
  header: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  headerLogo: {
    positions: 'relative',
    display: 'inline-block',
    width: '150px',
    overflow: 'hidden',
    objectFit: 'contain',
    objectPosition: 'center',
    margin: '0 auto',
  },
  headerLink: {
    position: 'relative',
    display: 'inherit',
    textAlign: 'right',
    alignSelf: 'flex-end',
    color: theme.palette.info.main,
    marginBottom: '5px',
  },
  body: {
    display: 'block',
    position: 'relative',
    width: '100%',
  },
  paper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    overflow: 'hidden',
  },
  left: {
    position: 'relative',
    display: 'block',
    width: '37%',
    flex: 0,
    flexBasis: '37%',
    flexShrink: 9999,
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  leftContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
  },
  leftContent2: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    padding: '1.2rem',
  },
  hiderHead: {
    position: 'relative',
    marginBottom: '1.2rem',
  },
  avatarName: { fontSize: '12px', fontWeight: 'bold', color: '#fff' },
  avatarSlug: { fontSize: '10px' },
  typos: {
    position: 'relative',
    fontSize: '12px',
    margin: '16px 0',
  },
  leftHeader: {
    display: 'block',
    color: '#fff',
    fontWeight: 'bold',
    width: '50%',
    textAlign: 'center',
    margin: '1rem 0',
  },
  svg: {
    display: 'block',
    position: 'relative',
    width: '100%',
    flexShrink: 0,
    flexBasis: '50%',
    objectFit: 'scale-down',
    objectPosition: 'bottom',
  },
  right: {
    position: 'relative',
    display: 'block',
    width: '63%',
    flex: 1,
    flexBasis: '63%',
    flexShrink: 1,
  },
  rightHeader: {
    display: 'block',
    position: 'relative',
    padding: '18px 1rem',
    width: '100%',
  },
  rightHeaderTitle: {
    display: 'block',
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  rightBody: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: '0 1.5rem',
  },
  form: {
    display: 'block',
    position: 'relative',
    width: '100%',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
  },
  footerLink: {
    display: 'inline-block',
    textAlign: 'center',
    color: theme.palette.info.main,
    margin: '14px auto',
  },
  authLink: {
    display: 'block',
    position: 'relative',
    textAlign: 'center',
    width: '100%',
  },
  authLinkChild: {
    display: 'inline-block',
    color: theme.palette.info.main,
  },
  loader: {
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
  },
  forgot: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: '1rem 0',
  },
  forgotHeader: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  forgotText: {
    fontSize: '14px',
    padding: '0 12px',
  },
  forgotIcon: {
    color: 'gray',
    fontSize: '40px',
  },
  special: {
    marginBottom: '0px',
    marginTop: '13px',
    paddingBottom: 0,
    [theme.breakpoints.down('xs')]: {
      marginBottom: '10px',
    },
  },
  line: {
    display: 'block',
    width: '100%',
    margin: '.7rem 0',
  },
  label: {
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#000',
  },
  textField: {
    marginTop: '12px',
    '& .MuiOutlinedInput-root > .MuiOutlinedInput-input': {
      padding: '14px',
    },
  },
  textField2: {
    '& .MuiOutlinedInput-root > .MuiOutlinedInput-input': {
      padding: '14px',
    },
  },
}));

export default useStyles;

import axios from 'axios';
import axiosWordpressApi from './utils';

const getBlogPosts = async ({ path, params = {} }) => {
  try {
    const response = await axiosWordpressApi.get(path, params);
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      // error hadnling for axios
    }
    throw new Error(error);
  }
};

export default getBlogPosts;

import { handleAction, handleActions, combineActions } from 'redux-actions';
import reduceReducers from 'reduce-reducers';

import { Action, ChildrenState } from '../types';
import { findAsyncActions } from '../utils';
import { LOGOUT } from '../auth/auth.types';
import {
  CLOSE_NEW_CHILD_MODAL,
  CLOSE_LINK_CHILD_MODAL,
} from '../user/user.types';
import * as types from './child.types';

const initialState: ChildrenState = {
  status: 'pending',
  error: undefined,
  children: [],
};

const requestReducer = handleAction(
  combineActions(...findAsyncActions(types, 'REQUEST')),
  (state: ChildrenState) => {
    return { ...state, status: 'pending' };
  },
  initialState
);

const errorReducer = handleAction(
  combineActions(...findAsyncActions(types, 'FAILURE')),
  (state: ChildrenState, action: Action<string>) => {
    return { ...state, status: 'failed', error: action.payload };
  },
  initialState
);

const successReducer = handleActions(
  {
    [LOGOUT.SUCCESS]: () => ({
      ...initialState,
    }),
    [types.GET_CHILDREN.SUCCESS]: (
      state: ChildrenState,
      action: Action<any>
    ) => {
      return {
        ...state,
        children: action.payload,
        status: 'success',
        error: undefined,
      };
    },
    [types.GET_CHILD.SUCCESS]: (state: ChildrenState, action: Action<any>) => {
      return {
        ...state,
        ...action.payload,
        status: 'success',
        error: undefined,
      };
    },
    [types.CREATE_CHILD.SUCCESS]: (
      state: ChildrenState,
      action: Action<any>
    ) => {
      return {
        ...state,
        status: 'submitted',
        error: undefined,
      };
    },
    [types.LINK_CHILD.SUCCESS]: (state: ChildrenState, action: Action<any>) => {
      return {
        ...state,
        status: 'submitted',
        error: undefined,
      };
    },
    [CLOSE_NEW_CHILD_MODAL]: (state: ChildrenState) => {
      return {
        ...state,
        error: undefined,
      };
    },
    [CLOSE_LINK_CHILD_MODAL]: (state: ChildrenState) => {
      return {
        ...state,
        error: undefined,
      };
    },
    [types.CREATE_LINK_CHILD.SUCCESS]: (
      state: ChildrenState,
      action: Action<any>
    ) => {
      return {
        ...state,
        status: 'submitted',
        error: undefined,
      };
    },
    [types.UPDATE_CHILD_PASSWORD.SUCCESS]: (
      state: ChildrenState,
      action: Action<any>
    ) => {
      return {
        ...state,
        status: 'submitted',
        error: undefined,
      };
    },
    [types.CHANGE_GRADE_LEVEL.REQUEST]: (state: ChildrenState) => {
      return {
        ...state,
        error: undefined,
      };
    },
    [types.CHANGE_GRADE_LEVEL.SUCCESS]: (state: ChildrenState) => {
      return {
        ...state,
        status: 'success',
        error: undefined,
      };
    },
  },
  initialState
);

export default reduceReducers(requestReducer, errorReducer, successReducer);

import React from 'react';
import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
} from '@material-ui/core';
import classnames from 'classnames';
import { PLANS, PlanDetail } from '../../../../config/plans';
import useStyles from './styles';

interface PlanTabsProps {
  selectedPlan: PlanDetail | undefined;
  onSelect: (plan: PlanDetail) => void;
}

const PlanTabs: React.FC<PlanTabsProps> = ({ onSelect, selectedPlan }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.root}>
      {Object.values(PLANS).map((plan: PlanDetail) => (
        <Grid item xs={12} sm={6} key={plan.name}>
          <Card
            className={classnames(
              classes.planCard,
              selectedPlan?.name === plan.name && classes.selectedPlan
            )}>
            <ButtonBase
              onClick={() => onSelect(plan)}
              style={{
                display: 'block',
                height: '100%',
                textAlign: 'left',
                width: '100%',
              }}>
              <CardContent className={classes.planCardContent}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  className={classes.planCardHeader}>
                  ${plan.amount} USD{plan.amountSuffix}
                </Typography>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  className={classes.planCardCaption}>
                  {plan.subheader}
                </Typography>
                <Box className={classes.chip}>
                  {plan.discount && (
                    <Chip
                      size="small"
                      label={plan.discount}
                      className={classes.chipText}
                    />
                  )}
                  {plan?.mostPopular && (
                    <Typography
                      variant="caption"
                      color="textPrimary"
                      id="popular"
                      className={classes.planCardPopular}>
                      Most Popular
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </ButtonBase>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default PlanTabs;

import { createActions } from 'redux-actions';

import * as types from './auth.types';

export const {
  loginWithEmail,
  loginWithGoogle,
  loginWithApple,
  signupWithEmail,
  resetPassword,
  updatePassword,
  updateEmail,
  sendEmailVerification,
  verifyRole,
  logout,
} = createActions(
  types.LOGIN_WITH_EMAIL.REQUEST,
  types.LOGIN_WITH_GOOGLE.REQUEST,
  types.LOGIN_WITH_APPLE.REQUEST,
  types.SIGNUP_WITH_EMAIL.REQUEST,
  types.RESET_PASSWORD.REQUEST,
  types.UPDATE_PASSWORD.REQUEST,
  types.UPDATE_EMAIL.REQUEST,
  types.SEND_EMAIL_VERIFICATION.REQUEST,
  types.VERIFY_ROLE.REQUEST,
  types.LOGOUT.REQUEST
);

import React from 'react';

import MpSuspense from '../ui/MpSuspense';

export default function withSuspense(WrappedComponent: React.FC) {
  return (props) => {
    return (
      <MpSuspense>
        <WrappedComponent {...props} />
      </MpSuspense>
    );
  };
}

import { handleAction, handleActions, combineActions } from 'redux-actions';
import reduceReducers from 'reduce-reducers';
import { Action, AuthState } from '../types';
import { findAsyncActions } from '../utils';
import * as childrenTypes from '../child/child.types';
import * as types from './auth.types';

const initialState: AuthState = {
  status: 'pending',
  error: null,
  token: null,
  uid: null,
  role: null,
  created: false,
  tempRole: null,
  callBack: null,
};

const requestReducer = handleAction(
  combineActions(...findAsyncActions(types, 'REQUEST')),
  (state: AuthState) => {
    return { ...state, status: 'pending' };
  },
  initialState
);

const errorReducer = handleAction(
  combineActions(...findAsyncActions(types, 'FAILURE')),
  (state: AuthState, action: Action<string>) => {
    return { ...state, status: 'failed', error: action.payload };
  },
  initialState
);

const successReducer = handleActions(
  {
    [types.VERIFY_ROLE.SUCCESS]: (state: AuthState, action: Action<any>) => ({
      ...state,
      status: 'success',
      token: action.payload.token,
      uid: action.payload.uid,
      signInProvider: action.payload.signInProvider,
      email: action.payload.email,
      role: state.role || action.payload.role || null,
      tempRole: null,
    }),
    [types.LOGIN_WITH_EMAIL.SUCCESS]: (
      state: AuthState,
      action: Action<any>
    ) => ({
      ...state,
      status: 'success',
      token: action.payload.token,
      uid: action.payload.uid,
      tempRole: null,
    }),
    [types.LOGIN_WITH_GOOGLE.SUCCESS]: (
      state: AuthState,
      action: Action<any>
    ) => ({
      ...state,
      status: 'success',
      token: action.payload.token,
      uid: action.payload.uid,
      tempRole: null,
    }),
    [types.LOGIN_WITH_APPLE.SUCCESS]: (
      state: AuthState,
      action: Action<any>
    ) => ({
      ...state,
      status: 'success',
      token: action.payload.token,
      uid: action.payload.uid,
      tempRole: null,
    }),
    [types.SIGNUP_WITH_EMAIL.REQUEST]: (
      state: AuthState,
      action: Action<any>
    ) => ({
      ...state,
      status: 'pending',
      tempRole: action.payload?.role || 'parent',
    }),
    [types.SIGNUP_WITH_EMAIL.SUCCESS]: (
      state: AuthState,
      action: Action<any>
    ) => ({
      ...state,
      status: 'success',
      role: state.role || action.payload?.role || 'parent',
      tempRole: 'parent',
    }),
    [types.SIGNUP_WITH_EMAIL.DONE]: (state: AuthState) => ({
      ...state,
      created: true,
    }),
    [types.RESET_PASSWORD.SUCCESS]: (state: AuthState) => ({
      ...state,
      status: 'failed',
    }),
    [types.UPDATE_EMAIL.SUCCESS]: (state: AuthState) => ({
      ...state,
      status: 'success',
      tempRole: null,
    }),
    [types.UPDATE_PASSWORD.SUCCESS]: (state: AuthState) => ({
      ...state,
      status: 'success',
    }),
    [types.SEND_EMAIL_VERIFICATION.SUCCESS]: (state: AuthState) => ({
      ...state,
      status: 'success',
    }),
    [types.VERIFY_EMAIL.SUCCESS]: (state: AuthState) => ({
      ...state,
      status: 'success',
      tempRole: null,
    }),
    // Setting this here so creation for child will update
    // child verification state (after failure redirect)
    [childrenTypes.CREATE_CHILD.SUCCESS]: (state: AuthState) => ({
      ...state,
      status: 'success',
    }),
    [types.LOGOUT.SUCCESS]: (state: AuthState, action: Action<any>) => ({
      ...initialState,
      status: 'failed',
      callBack: action.payload,
    }),
  },
  initialState
);

export default reduceReducers(requestReducer, errorReducer, successReducer);

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    maxWidth: '800px',
    margin: 0,
    padding: theme.spacing(1),
  },
  dialogTitle: {
    display: 'block',
    position: 'relative',
  },
  dialogTitleText: {
    display: 'block',
    position: 'relative',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '0 1rem',
    fontSize: '20px',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
  },
  closeButtonWhite: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: '#fff',
  },
  container: {
    padding: '10px',
  },
  primaryBg: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
}));

export default useStyles;

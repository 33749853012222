import React from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { PaymentMethod } from '@stripe/stripe-js';
import CardForm from '../../../forms/CardForm';
import useStyles from './styles';

const PaymentDetails: React.FC<{
  cards: PaymentMethod[];
  onSave: (card) => void;
  showCard: boolean;
  setShowCard: () => void;
}> = ({ onSave, cards, showCard, setShowCard }) => {
  const classes = useStyles();
  const [cardId, setCardId] = React.useState<string | undefined>('');

  const handleChange = (event) => {
    event.persist();
    setCardId(event.target.value);
  };

  React.useEffect(() => {
    if (cards.length > 0) {
      setCardId(cards[0].id!);
      // sets showCard to false if cards exists
      setShowCard();
    } else {
      // sets showCard to true if no saved cards exists
      setShowCard();
    }
  }, [cards, setShowCard]);

  React.useEffect(() => {
    if (cardId) {
      onSave({ id: cardId });
    }
  }, [cardId, onSave]);

  const switchPaymentMethod = () => {
    setShowCard();
    setCardId('');
    onSave(undefined);
  };

  return (
    <Box component="div" className={classes.root}>
      <Typography variant="h5" gutterBottom className={classes.header}>
        Payment method
      </Typography>
      <Box component="div" className={classes.cardContainer}>
        {cards.length > 0 && !showCard && (
          <FormControl
            fullWidth
            variant="outlined"
            style={{ marginTop: '6px' }}>
            <InputLabel htmlFor="paymentMethod">Select Card</InputLabel>
            <Select
              label="Select Card"
              id="paymentMethod"
              name="paymentMethod"
              value={cardId}
              onChange={handleChange}>
              {cards.length > 0 &&
                cards.map(({ id, card, ...rest }) => (
                  <MenuItem key={id} dense value={id}>
                    <Typography
                      variant="caption"
                      style={{
                        fontWeight: 'bold',
                        textTransform: 'capitalize',
                      }}>{`${card?.brand} ending in ${card?.last4}-${card?.exp_month}/${card?.exp_year}`}</Typography>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {showCard && <CardForm />}
        <Button
          variant="text"
          color="secondary"
          onClick={switchPaymentMethod}
          style={{ fontSize: '14px', textTransform: 'inherit' }}>
          {!showCard ? 'Add new card' : 'Use saved card'}
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentDetails;

import { call, put, takeLatest, select } from 'redux-saga/effects';
import Swal from 'sweetalert2';
// import { push } from 'connected-react-router';
import { Action, ApplicationState } from '../types';
import { setLoading as setLoadingAction } from '../app/app.actions';
import * as Firebase from '../../services/firebase';
import { PLANS } from '../../config/plans';
import { dataLayerPush as dataLayerPushAction } from '../analytics/analytics.actions';
import { updateUser } from '../user/user.actions';
import { getStripeId } from './stripe.selectors';
import * as types from './stripe.types';

function* stripeGetUser(action: Action<{ stripeId: string }>) {
  try {
    const response = yield call(Firebase.getCustomer, action.payload!);
    const subscriptions = yield call(
      Firebase.getSubscriptions,
      action.payload!
    );
    yield put({
      type: types.STRIPE_GET_USER.SUCCESS,
      payload: { ...response, ...subscriptions },
    });
  } catch (err) {
    yield put({ type: types.STRIPE_GET_USER.FAILURE, payload: err.message });
  }
}

function* stripeGetCards(action: Action<{ stripeId: string }>) {
  try {
    const response = yield call(Firebase.getCards, action.payload!);
    yield put({ type: types.STRIPE_GET_CARDS.SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: types.STRIPE_GET_CARDS.FAILURE, payload: err.message });
  }
}

function* stripeCreateSource(
  action: Action<{ stripeId: string; tokenId: string }>
) {
  try {
    yield put(setLoadingAction(true));
    const response = yield call(Firebase.createSource, action.payload!);
    yield put({ type: types.STRIPE_CREATE_SOURCE.SUCCESS, payload: response });
    yield Swal.fire('Success', 'Credit card has been added.', 'success');
  } catch (err) {
    yield put({
      type: types.STRIPE_CREATE_SOURCE.FAILURE,
      payload: err.message,
    });
    yield Swal.fire('Error', err.message, 'error');
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* stripeUpdateCustomer(
  action: Action<{ customerId: string; firstName: string; lastName: string }>
) {
  try {
    yield put(setLoadingAction(true));
    const response = yield call(Firebase.updateCustomer, action.payload!);
    yield put({ type: types.STRIPE_UPDATE_USER.SUCCESS, payload: response });
    // yield Swal.fire('Success', 'User Updated.', 'success');
  } catch (err) {
    yield put({
      type: types.STRIPE_UPDATE_USER.FAILURE,
      payload: err.message,
    });
    yield Swal.fire('Error', err.message, 'error');
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* stripeDeleteSource(
  action: Action<{ stripeId: string; tokenId: string }>
) {
  try {
    yield put(setLoadingAction(true));
    const stripeId: string = yield select(getStripeId);
    const response = yield call(Firebase.deleteSource, action.payload!);
    yield put({ type: types.STRIPE_DELETE_SOURCE.SUCCESS, payload: response });
    yield Swal.fire('Success', 'Credit card has been deleted.', 'success');
    yield put({ type: types.STRIPE_GET_USER.REQUEST, payload: { stripeId } });
    yield put({ type: types.STRIPE_GET_CARDS.REQUEST, payload: { stripeId } });
  } catch (err) {
    yield put({
      type: types.STRIPE_DELETE_SOURCE.FAILURE,
      payload: err.message,
    });
    yield Swal.fire('Error', err.message, 'error');
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* stripePurchaseSubscription(
  action: Action<{
    stripeId: string;
    tokenId?: string;
    planId: string;
    childUid?: string;
    couponCode?: string;
    totalAmount: number;
    isCheckoutPage?: boolean;
    trialPeriod?: number;
    successPath?: string;
  }>
) {
  try {
    yield put(setLoadingAction(true));
    console.log('successPath: ', action.payload?.successPath);
    const plan = Object.values(PLANS).find(
      (planDetail) => planDetail.id === action.payload?.planId
    );
    const state: ApplicationState = yield select();
    if (state.user.stripeId) {
      // create source
      if (action.payload?.tokenId) {
        const cardPm = action.payload.tokenId.includes('card');
        if (cardPm) {
          yield call(Firebase.createSourceFromPm, {
            stripeId: action.payload?.stripeId,
            tokenId: action.payload?.tokenId,
          });
        } else {
          yield call(Firebase.createSource, {
            stripeId: action.payload?.stripeId,
            tokenId: action.payload?.tokenId,
          });
        }
      }
      // purchase subscription
      const response = yield call(Firebase.purchaseSubscription, {
        stripeId: state.user.stripeId,
        planId: action.payload?.planId,
        childUid: action.payload?.childUid,
        couponCode: action.payload?.couponCode,
        trialPeriod: action.payload?.trialPeriod,
      });
      yield put({
        type: types.STRIPE_PURCHASE_SUBSCRIPTION.DONE,
        payload: action.payload?.successPath,
      });
      yield put({
        type: types.STRIPE_PURCHASE_SUBSCRIPTION.SUCCESS,
        payload: response,
      });
      yield put(
        dataLayerPushAction({
          event: 'premiumUpgrade',
          ecommerce: {
            purchase: {
              actionField: {
                id: response.subscription.id,
                affiliation: 'MoneyPrep',
                revenue: action.payload?.totalAmount,
                tax: '',
                coupon: '',
              },
              products: [
                {
                  name: plan?.name,
                  id: plan?.id,
                  price: action.payload?.totalAmount,
                  brand: 'MoneyPrep',
                  category: 'Premium Academy Membership',
                  quantity: 1,
                },
              ],
            },
          },
        })
      );
      yield put({
        type: types.STRIPE_GET_USER.REQUEST,
        payload: { stripeId: action.payload!.stripeId },
      });
    }
  } catch (err) {
    yield put({
      type: types.STRIPE_PURCHASE_SUBSCRIPTION.FAILURE,
      payload: err.message,
    });
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* stripePurchaseDone() {
  // console.log(action.payload?.successPath);
  yield put(
    updateUser({ data: { isProfileComplete: true }, successPath: '/redirect' })
  );
  yield Swal.fire({
    title: 'Congratulations!!',
    text: 'You now have access to all the Premium Learning games!',
    showConfirmButton: true,
    confirmButtonText: 'Continue',
    confirmButtonColor: '#fdad3a',
  });

  // yield put(push('/redirect'));
}

function* stripeCancelSubscription(
  action: Action<{ stripeId: string; subId: string }>
) {
  try {
    yield put(setLoadingAction(true));
    const response = yield call(Firebase.cancelSubscription, action.payload!);
    yield put(setLoadingAction(false));
    yield put({
      type: types.STRIPE_CANCEL_SUBSCRIPTION.SUCCESS,
      payload: response,
    });
    yield put({
      type: types.STRIPE_GET_USER.REQUEST,
      payload: { stripeId: action.payload!.stripeId },
    });
    yield Swal.fire('Success', 'Subscription has been cancelled.', 'success');
  } catch (err) {
    yield put(setLoadingAction(false));
    yield put({
      type: types.STRIPE_CANCEL_SUBSCRIPTION.FAILURE,
      payload: err.message,
    });
    yield Swal.fire('Error', err.message, 'error');
  }
}

function* stripeGetCouponCodeDetails(action: Action<{ couponCode: string }>) {
  try {
    const response = yield call(Firebase.getCouponCodeDetails, action.payload!);
    yield put({
      type: types.STRIPE_GET_COUPON_CODE_DETAILS.SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: types.STRIPE_GET_COUPON_CODE_DETAILS.FAILURE,
      payload: err.message,
    });
  }
}

export default function* stripeSaga() {
  yield takeLatest(types.STRIPE_GET_USER.REQUEST, stripeGetUser);
  yield takeLatest(types.STRIPE_GET_CARDS.REQUEST, stripeGetCards);
  yield takeLatest(types.STRIPE_UPDATE_USER.REQUEST, stripeUpdateCustomer);
  yield takeLatest(types.STRIPE_CREATE_SOURCE.REQUEST, stripeCreateSource);
  yield takeLatest(types.STRIPE_DELETE_SOURCE.REQUEST, stripeDeleteSource);
  yield takeLatest(
    types.STRIPE_PURCHASE_SUBSCRIPTION.REQUEST,
    stripePurchaseSubscription
  );
  yield takeLatest(types.STRIPE_PURCHASE_SUBSCRIPTION.DONE, stripePurchaseDone);
  yield takeLatest(
    types.STRIPE_CANCEL_SUBSCRIPTION.REQUEST,
    stripeCancelSubscription
  );
  yield takeLatest(
    types.STRIPE_GET_COUPON_CODE_DETAILS.REQUEST,
    stripeGetCouponCodeDetails
  );
}

import { handleAction, handleActions, combineActions } from 'redux-actions';
import reduceReducers from 'reduce-reducers';

import { Action, BlogPostsState } from '../types';
import { findAsyncActions } from '../utils';
import * as types from './wordpress.types';

const initialState: BlogPostsState = {
  status: 'pending',
  error: '',
  results: [],
};

const requestReducer = handleAction(
  combineActions(...findAsyncActions(types, 'REQUEST')),
  (state: BlogPostsState) => {
    return { ...state, status: 'pending' };
  },
  initialState
);

const errorReducer = handleAction(
  combineActions(...findAsyncActions(types, 'FAILURE')),
  (state: BlogPostsState, action: Action<string>) => {
    return { ...state, status: 'failed', error: action.payload };
  },
  initialState
);

const successReducer = handleActions(
  {
    [types.GET_BLOG_POSTS.SUCCESS]: (
      state: BlogPostsState,
      action: Action<any>
    ) => ({
      ...state,
      status: 'success',
      results: action.payload || [],
    }),
    [types.GET_TOP_STORIES.SUCCESS]: (
      state: BlogPostsState,
      action: Action<any>
    ) => ({
      ...state,
      status: 'success',
      stories: action.payload || [],
    }),
  },
  initialState
);

export default reduceReducers(requestReducer, errorReducer, successReducer);

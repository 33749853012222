// eslint-disable-next-line no-shadow
export enum PlanType {
  Monthly = 'Monthly',
  Biannually = '6-Months',
  Annually = 'Yearly',
}

export interface PlanDetail {
  id: string;
  name: string;
  subheader: string;
  amount: number;
  price: number;
  amountSuffix: '/month' | '/6 months' | '/year';
  buttonVariant: 'outlined' | 'contained';
  description: string[];
  message: (price: string) => void;
  discount?: string;
  mostPopular?: boolean;
}

export const PLANS: Record<PlanType, PlanDetail> = {
  [PlanType.Monthly]: {
    id: process.env.REACT_APP_STRIPE_MONTHLY_PLAN_ID!,
    name: 'Monthly Plan',
    subheader: '3-Days Free Trial',
    amount: 4.99,
    price: 4.99,
    amountSuffix: '/month',
    buttonVariant: 'outlined',
    description: [
      'Advanced MoneyPrep learning games',
      'Enhanced money management concepts',
      'Enhanced virtual items / rewards',
      'Video challenges',
      'Parent videos',
    ],
    message: (price: string) =>
      `3-Days free trial, then charged ${price} USD after trial.`,
    discount: '',
  },
  [PlanType.Biannually]: {
    id: process.env.REACT_APP_STRIPE_BIANNUALLY_PLAN_ID!,
    name: '6-Months',
    subheader: ' Billed $53.95 USD per 6 months',
    amount: 8.99,
    price: 53.95,
    amountSuffix: '/month',
    buttonVariant: 'contained',
    description: [
      'Advanced MoneyPrep learning games',
      'Enhanced money management concepts',
      'Enhanced virtual items / rewards',
      'Video challenges',
      'Parent videos',
    ],
    message: (price: string) =>
      `You will be charged ${price} USD for the first 6 months. `,
    discount: 'Save 10%',
    mostPopular: true,
  },
  [PlanType.Annually]: {
    id: process.env.REACT_APP_STRIPE_ANNUALLY_PLAN_ID!,
    name: 'Yearly',
    subheader: ' Billed $95.88 USD per year',
    amount: 7.99,
    price: 95.88,
    amountSuffix: '/month',
    buttonVariant: 'outlined',
    description: [
      'Advanced MoneyPrep learning games',
      'Enhanced money management concepts',
      'Enhanced virtual items / rewards',
      'Video challenges',
      'Parent videos',
    ],
    message: (price: string) =>
      `You will be charged ${price} USD for the first year.`,
    discount: 'Save 20%',
  },
};

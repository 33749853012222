import { createActions } from 'redux-actions';

import * as types from './child.types';

export const {
  getChild,
  getChildren,
  createChild,
  linkChild,
  createLinkChild,
  updateChild,
  deleteChild,
  updateChildPassword,
  changeGradeLevel,
} = createActions(
  types.GET_CHILD.REQUEST,
  types.GET_CHILDREN.REQUEST,
  types.CREATE_CHILD.REQUEST,
  types.LINK_CHILD.REQUEST,
  types.CREATE_LINK_CHILD.REQUEST,
  types.DELETE_CHILD.REQUEST,
  types.UPDATE_CHILD.REQUEST,
  types.UPDATE_CHILD_PASSWORD.REQUEST,
  types.CHANGE_GRADE_LEVEL.REQUEST
);

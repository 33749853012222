import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Typography } from '@material-ui/core';
import { motion } from 'framer-motion/dist/framer-motion';

const bounceTransition = {
  y: {
    duration: 0.4,
    yoyo: Infinity,
    ease: 'easeOut',
  },
  opacity: {
    duration: 0.4,
    yoyo: Infinity,
    ease: 'easeOut',
    repeatDelay: 0.4,
  },
};

const LoadingScreen = () => {
  const classes = useStyles();

  return (
    <Box component="div" className={classes.main}>
      <motion.div
        transition={bounceTransition}
        animate={{
          y: ['10%', '-10%'],
          opacity: [1, 0.8, 0.6, 0.4, 0.2],
        }}>
        <Avatar src="/media/mp.png" className={classes.logo} />
      </motion.div>
      <Typography variant="subtitle1" className={classes.text}>
        Please wait...
      </Typography>
    </Box>
  );
};

export default LoadingScreen;

const useStyles = makeStyles((theme) => ({
  main: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100%',
    placeItems: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.primary.main,
    padding: 0,
    margin: 0,
  },
  text: {
    color: 'inherit',
    textAlign: 'center',
    fontSize: '20px',
    marginTop: '1rem',
  },
  progress: {
    color: theme.palette.primary.main,
    width: '150px',
    height: '150px',
  },
  logo: {
    width: '150px',
    height: '150px',
    margin: '0 auto',
    objectPosition: 'center',
    objectFit: 'cover',
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

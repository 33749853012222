import React from 'react';
import { Avatar, Box, Hidden, Typography } from '@material-ui/core';
import { PaymentMethod } from '@stripe/stripe-js';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Alert } from '@material-ui/lab';
import ModalCard from '../../ui/ModalCard';
import { PlanDetail } from '../../../config/plans';
import {
  AppState,
  ChildrenState,
  StripeState,
  UserState,
} from '../../../redux/types';
import OrderDetails from './OrderDetails/OrderDetails';
import PaymentDetails from './PaymentDetails';
import PlanTabs from './PlanTabs';
import useStyles from './styles';

interface NewSubscriptionModalProps {
  childUid: string | undefined;
  user: UserState;
  app: AppState;
  stripe: StripeState;
  childrenUsers: ChildrenState;
  cards: PaymentMethod[];
  coupon: any;
  isOpen: boolean;
  onClose: () => void;
  stripeGetCouponCodeDetails: ({ couponCode: string }) => void;
  stripeGetUser: (data: { stripeId: string }) => void;
  stripeGetCards: (data: { stripeId: string }) => void;
  stripePurchaseSubscription: (data: {
    stripeId: string;
    planId: string;
    childUid: string;
    tokenId?: string;
    couponCode?: string;
    totalAmount: number;
  }) => void;
}

const NewSubscriptionModal: React.FC<NewSubscriptionModalProps> = ({
  childUid,
  app,
  user,
  stripe,
  childrenUsers,
  cards,
  coupon,
  isOpen,
  onClose,
  stripeGetCouponCodeDetails,
  stripeGetUser,
  stripeGetCards,
  stripePurchaseSubscription,
}) => {
  const classes = useStyles();
  const stripeEl = useStripe();
  const elements = useElements();
  const [stripeError, setStripeError] = React.useState<string | undefined>(
    undefined
  );

  const [cardError, setCardError] = React.useState<string | undefined>(
    undefined
  );

  const [showCard, setShowCard] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (user.stripeId) {
      const { stripeId } = user;
      stripeGetUser({ stripeId });
      stripeGetCards({ stripeId });
    }
  }, [user, stripeGetCards, stripeGetUser, childrenUsers.status]);

  // store selected plan in state
  const [selectedPlan, setSelectedPlan] = React.useState<
    PlanDetail | undefined
  >(undefined);

  // store card details in state
  const [selectedCard, setSelectedCard] = React.useState<
    PaymentMethod | undefined
  >(undefined);

  // handle select plan
  const handleSelectPlan = React.useCallback((plan: PlanDetail) => {
    setSelectedPlan(plan);
  }, []);

  // handle select card
  const handleSelectCard = React.useCallback((card: PaymentMethod) => {
    setSelectedCard(card);
  }, []);

  const handleShowStripeCardField = React.useCallback(() => {
    setShowCard((prevState) => !prevState);
  }, []);

  // handle purchase subscription
  const handlePurchaseSubscription = React.useCallback(
    (card: PaymentMethod, plan: PlanDetail, totalAmount: number) => {
      if (user.stripeId && childUid) {
        stripePurchaseSubscription({
          stripeId: user.stripeId!,
          planId: plan.id,
          childUid: childUid!,
          ...(card?.id && { tokenId: card.id }),
          ...(stripe.coupon &&
            stripe.coupon.valid && { couponCode: stripe.coupon.id }),
          totalAmount,
        });
      } else {
        setStripeError(
          'We are unable to process your subscription at this time. Please contact our support.'
        );
      }
    },
    [childUid, stripe.coupon, stripePurchaseSubscription, user.stripeId]
  );

  // handle confirm purchase
  const handleConfirm = React.useCallback(
    async (totalAmount: number) => {
      if (!stripeEl || !elements) {
        return;
      }
      const cardElement = elements.getElement(CardElement);
      const result = cardElement
        ? await stripeEl.createToken(cardElement)
        : null;

      if (!childUid) {
        setStripeError('Please select a child to proceed.');
      }

      if (!selectedPlan) {
        setStripeError('Please select a plan to proceed.');
      }

      if (selectedPlan && childUid) {
        setCardError(undefined);
        setStripeError(undefined);
        if (!result) {
          if (!result && showCard) {
            setCardError('Please enter a valid credit card details.');
          }
          if (!selectedCard) {
            setCardError('Please choose a valid credit card.');
          } else {
            handlePurchaseSubscription(selectedCard, selectedPlan, totalAmount);
          }
        }

        if (result) {
          const { error, token } = result;
          if (error) {
            setCardError(
              error.message || 'Please enter a valid credit card details.'
            );
          }
          if (!error && selectedCard) {
            setCardError(undefined);
          }
          if (token && !error) {
            handlePurchaseSubscription(
              { id: token.id } as PaymentMethod,
              selectedPlan,
              totalAmount
            );
          }
        }
      }
    },
    [
      stripeEl,
      elements,
      childUid,
      selectedPlan,
      showCard,
      selectedCard,
      handlePurchaseSubscription,
    ]
  );

  // handle apply coupon
  const handleApplyCoupon = React.useCallback(
    (couponCode: string) => {
      stripeGetCouponCodeDetails({ couponCode });
    },
    [stripeGetCouponCodeDetails]
  );

  React.useEffect(() => {
    if (!isOpen && onClose) {
      setSelectedPlan(undefined);
      setSelectedCard(undefined);
    }
  }, [isOpen, onClose]);

  return (
    <ModalCard onClose={onClose}>
      <Box component="div" style={{ maxWidth: '800px' }}>
        <Box component="div" className={classes.content}>
          <Box component="div" className={classes.contentLeft}>
            <Box component="div" style={{ padding: '10px' }}>
              <Typography
                variant="h5"
                style={{ fontWeight: 'bold', fontSize: '20px' }}>
                Start Your Premium Membership
              </Typography>
              <Typography variant="caption" style={{ fontSize: '13px' }}>
                Get unlimited access to all games.
              </Typography>
            </Box>
            <Box component="div" className={classes.formTab}>
              {(stripe.error || stripeError) && (
                <Alert variant="filled" severity="error">
                  {stripe.error || stripeError}
                </Alert>
              )}
              <PaymentDetails
                cards={cards}
                onSave={handleSelectCard}
                showCard={showCard}
                setShowCard={handleShowStripeCardField}
              />
              <Typography
                variant="caption"
                style={{
                  color: 'red',
                  fontSize: '12px',
                  padding: '0px 1rem',
                }}>
                {cardError}
              </Typography>
            </Box>
            <Box component="div" className={classes.planBox}>
              <Typography variant="h5" className={classes.planTitle}>
                Pick a plan
              </Typography>
              <PlanTabs
                selectedPlan={selectedPlan}
                onSelect={handleSelectPlan}
              />
            </Box>
            <Box component="div" className={classes.orderTab}>
              <OrderDetails
                selectedPlan={selectedPlan}
                coupon={coupon}
                applyCoupon={handleApplyCoupon}
                onConfirm={handleConfirm}
                app={app}
              />
            </Box>
          </Box>
          <Hidden xsDown>
            <Box component="div" className={classes.contentRight}>
              <Typography
                variant="h5"
                style={{
                  display: 'block',
                  fontSize: '16px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  padding: '8px',
                }}>
                Premium Member Benefits
              </Typography>
              <Box component="div" className={classes.items}>
                <Avatar
                  className={classes.itemIcon}
                  src="/media/moneyconcept.png"
                />
                <Typography
                  variant="caption"
                  className={classes.itemText}
                  style={{ fontWeight: 'bold' }}>
                  Advanced Money Concepts
                </Typography>
              </Box>
              <Box component="div" className={classes.items}>
                <Avatar className={classes.itemIcon} src="/media/store.png" />
                <Typography
                  variant="caption"
                  className={classes.itemText}
                  style={{ fontWeight: 'bold' }}>
                  Virtual Store Experience
                </Typography>
              </Box>
              <Box component="div" className={classes.items}>
                <Avatar
                  className={classes.itemIcon}
                  src="/media/influence.png"
                />
                <Typography
                  variant="caption"
                  className={classes.itemText}
                  style={{ fontWeight: 'bold' }}>
                  Measurable Influence
                </Typography>
              </Box>
            </Box>
          </Hidden>
        </Box>
      </Box>
    </ModalCard>
  );
};

export default NewSubscriptionModal;

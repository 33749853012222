import React from 'react';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import classnames from 'classnames';
import gradeLevels from '../../../config/gradeLevels';
import { AppState, ChildrenState, UserState } from '../../../redux/types';
import useStyles from './styles';

interface ChangeGradeLevelProps {
  playerId: string;
  newGradeLevel: number;
}

interface ChangeGradeModalProps {
  user: UserState;
  app: AppState;
  childrenUsers: ChildrenState;
  childUid: string | undefined;
  onClose: () => void;
  changeGradeLevel: (payload: ChangeGradeLevelProps) => void;
}

const ChangeGradeModal: React.FC<ChangeGradeModalProps> = ({
  app,
  user,
  childrenUsers,
  childUid,
  onClose,
  changeGradeLevel,
}) => {
  const classes = useStyles();
  const [gradeLevel, setGradeLevel] = React.useState<number>(1);
  const [gradeChangeCount, setGradeChangeCount] = React.useState<number>(0);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [activeStep, setActiveStep] = React.useState<number>(0);

  const getChildGradeWUid = React.useMemo(() => {
    if (childUid && childrenUsers.children.length > 0) {
      const result = childrenUsers.children.find((res) => res.uid === childUid);
      return result?.gradeLevel;
    }
    return '';
  }, [childUid, childrenUsers.children]);

  const reviewChangeGradeLevel = React.useCallback(() => {
    const oldGradeLevel = getChildGradeWUid;
    if (gradeLevel === Number(oldGradeLevel)) {
      setError('Please select another grade level.');
    } else if (!gradeLevel) {
      setError('Please select a grade level.');
    } else {
      setActiveStep(1);
      setError(undefined);
    }
  }, [getChildGradeWUid, gradeLevel]);
  const handleChangeGradeLevel = React.useCallback(() => {
    if (gradeLevel && childUid) {
      changeGradeLevel({ playerId: childUid, newGradeLevel: gradeLevel });
    }
  }, [changeGradeLevel, childUid, gradeLevel]);

  React.useEffect(() => {
    if (childUid && childrenUsers.children.length > 0) {
      const result = childrenUsers.children.find((res) => res.uid === childUid);
      setGradeChangeCount(Number(result?.gradeChangeCount) || 0);
    }
  }, [childUid, childrenUsers.children]);

  return (
    <Paper className={classes.root}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="caption" className={classes.dialogTitleText}>
          Change Grade
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.container}>
        <Box component="div" style={{ maxWidth: '500px', minWidth: '400px' }}>
          <Typography variant="h5" className={classes.subText}>
            Note: You can only change grade twice per year
          </Typography>
          <Typography variant="subtitle1" className={classes.subText1}>
            Total changes remaining: {`${2 - gradeChangeCount}/2`}
          </Typography>
          <Box component="div" className={classes.content}>
            <Box className={classes.box}>
              <Typography variant="h5" className={classes.header}>
                Current Grade
              </Typography>
              <Typography variant="h5" className={classes.header1}>
                {`Grade ${getChildGradeWUid}`}
              </Typography>
            </Box>
            <Box className={classes.box}>
              <Box
                className={classnames(activeStep === 1 && classes.boxColored)}>
                <Typography variant="h5" className={classes.header}>
                  New Grade
                </Typography>
                {activeStep === 0 && (
                  <>
                    <FormControl
                      variant="outlined"
                      className={classes.gradeSelect}>
                      <Select
                        className={classes.gradeSelectChild}
                        id="gradeLevel"
                        name="gradeLevel"
                        onChange={(e) => setGradeLevel(Number(e.target.value))}
                        value={gradeLevel}>
                        {gradeLevels.map((grade) => (
                          <MenuItem key={grade.value} value={grade.value}>
                            {grade.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {error && (
                        <FormHelperText
                          style={{
                            color: 'red',
                            fontSize: '14px',
                            margin: '5px 0',
                          }}>
                          {error}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.styledButton}
                      onClick={reviewChangeGradeLevel}
                      disabled={Number(2 - gradeChangeCount) === 0}>
                      Continue to Review
                    </Button>
                  </>
                )}
                {activeStep === 1 && (
                  <Typography variant="h5" className={classes.header1}>
                    {`Grade ${gradeLevel}`}
                  </Typography>
                )}
              </Box>
              {activeStep === 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.styledButton}
                  disabled={app.loading}
                  onClick={handleChangeGradeLevel}>
                  {app.loading ? 'Saving...' : 'Confirm'}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Paper>
  );
};

export default ChangeGradeModal;

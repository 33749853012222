import { handleAction, handleActions, combineActions } from 'redux-actions';
import reduceReducers from 'reduce-reducers';

import { Action, UserState } from '../types';
import { findAsyncActions } from '../utils';
import { LOGOUT } from '../auth/auth.types';
import * as types from './user.types';

const initialState: UserState = {
  status: 'pending',
  error: null,
  profile: {
    isProfileComplete: false,
    firstName: null,
    email: null,
    role: null,
  },
  stripeId: null,
};

const requestReducer = handleAction(
  combineActions(...findAsyncActions(types, 'REQUEST')),
  (state: UserState) => {
    return state;
  },
  initialState
);

const errorReducer = handleAction(
  combineActions(...findAsyncActions(types, 'FAILURE')),
  (state: UserState, action: Action<string>) => {
    return { ...state, status: 'failed', error: action.payload };
  },
  initialState
);

const successReducer = handleActions(
  {
    [LOGOUT.SUCCESS]: () => ({
      ...initialState,
    }),
    [types.GET_USER.SUCCESS]: (state: UserState, action: Action<any>) => {
      // ...state,
      // status: 'success',
      // token: action.payload.token,
      // uid: action.payload.uid,
      // signInProvider: action.payload.signInProvider,
      // email: action.payload.email,
      // role: state.role || action.payload.role,
      return {
        ...state,
        profile: action.payload,
        ...(action.payload.stripeId && { stripeId: action.payload.stripeId }),
        status: 'success',
      };
    },
    [types.OPEN_VIDEO_MODAL]: (state: UserState) => {
      return {
        ...state,
        showVideoModal: true,
      };
    },
    [types.CLOSE_VIDEO_MODAL]: (state: UserState) => {
      return {
        ...state,
        showVideoModal: false,
      };
    },
    [types.OPEN_ONBOARDING_MODAL]: (state: UserState) => {
      return {
        ...state,
        showOnboardingModal: true,
      };
    },
    [types.CLOSE_ONBOARDING_MODAL]: (state: UserState) => {
      return {
        ...state,
        showOnboardingModal: false,
      };
    },
    [types.OPEN_PROMO_MODAL]: (state: UserState, action: Action<any>) => {
      return {
        ...state,
        showPromoModal: true,
        childUid: action.payload,
      };
    },
    [types.CLOSE_PROMO_MODAL]: (state: UserState) => {
      return {
        ...state,
        showPromoModal: false,
        childUid: undefined,
      };
    },
    [types.OPEN_NEW_CHILD_MODAL]: (state: UserState) => {
      return {
        ...state,
        showNewChildModal: true,
      };
    },
    [types.OPEN_LINK_CHILD_MODAL]: (state: UserState) => {
      return {
        ...state,
        showLinkChildModal: true,
      };
    },
    [types.CLOSE_NEW_CHILD_MODAL]: (state: UserState) => {
      return {
        ...state,
        showNewChildModal: false,
      };
    },
    [types.CLOSE_LINK_CHILD_MODAL]: (state: UserState) => {
      return {
        ...state,
        showLinkChildModal: false,
      };
    },
    [types.OPEN_NEW_SUB_MODAL]: (state: UserState, action: Action<any>) => {
      return {
        ...state,
        showNewSubModal: true,
        childUid: action.payload,
      };
    },
    [types.CLOSE_NEW_SUB_MODAL]: (state: UserState) => {
      return {
        ...state,
        showNewSubModal: false,
        childUid: undefined,
      };
    },
    [types.OPEN_CHANGE_SUB_MODAL]: (state: UserState, action: Action<any>) => {
      return {
        ...state,
        showChangeSubModal: true,
        childUid: action.payload,
      };
    },
    [types.CLOSE_CHANGE_SUB_MODAL]: (state: UserState) => {
      return {
        ...state,
        showChangeSubModal: false,
        childUid: undefined,
      };
    },
    [types.OPEN_CHANGE_GRADE_MODAL]: (
      state: UserState,
      action: Action<any>
    ) => {
      return {
        ...state,
        showChangeGradeModal: true,
        childUid: action.payload,
      };
    },
    [types.CLOSE_CHANGE_GRADE_MODAL]: (state: UserState) => {
      return {
        ...state,
        showChangeGradeModal: false,
        childUid: undefined,
      };
    },
  },
  initialState
);

export default reduceReducers(requestReducer, errorReducer, successReducer);

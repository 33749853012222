import { connect } from 'react-redux';
import {
  updateUser,
  openPromoModal,
  closePromoModal,
} from '../../redux/user/user.actions';
import { ApplicationState } from '../../redux/types';
import { AppProvider, useAppContext } from './AppContext';

const mapState = ({ user, stripe, children, router }: ApplicationState) => ({
  user,
  stripe,
  kids: children,
  router,
});

export default connect(mapState, {
  updateUser,
  openPromoModal,
  closePromoModal,
})(AppProvider);

export { useAppContext };

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'block',
    width: '100%',
    padding: '10px',
  },
  header: { fontSize: '16px', fontWeight: 'bold', marginBottom: '5px' },
  cardContainer: {
    position: 'relative',
    display: 'block',
    width: '100%',
  },
}));

export default useStyles;

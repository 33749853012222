import { connect } from 'react-redux';
import { ApplicationState } from '../../../redux/types';
import {
  stripeGetCouponCodeDetails,
  stripeCreateSource,
  stripeGetUser,
  stripeGetCards,
  stripePurchaseSubscription,
} from '../../../redux/stripe/stripe.actions';
import NewSubscriptionModal from './NewSubscriptionModal';

const mapState = ({ stripe, app, user, children }: ApplicationState) => ({
  app,
  stripe,
  user,
  cards: stripe.cards,
  coupon: stripe.coupon,
  childrenUsers: children,
});

export default connect(mapState, {
  stripeGetCouponCodeDetails,
  stripeGetUser,
  stripeGetCards,
  stripeCreateSource,
  stripePurchaseSubscription,
})(NewSubscriptionModal);

import React from 'react';
import {
  Typography,
  Button,
  Box,
  ListItem,
  TextField,
  ListItemText,
  CircularProgress,
  Link,
} from '@material-ui/core';
import numeral from 'numeral';
import { PlanDetail } from '../../../../config/plans';
import { AppState } from '../../../../redux/types';
import useStyles from './styles';

interface OrderDetailsProps {
  selectedPlan: PlanDetail | undefined;
  coupon: any;
  onConfirm: (amount: number) => void;
  applyCoupon: (couponCode: string) => void;
  app: AppState;
}

const OrderDetails: React.FC<OrderDetailsProps> = ({
  selectedPlan,
  coupon,
  applyCoupon,
  onConfirm,
  app,
}) => {
  const [couponCode, setCouponCode] = React.useState<string>('');
  const [totalAmount, setTotalAmount] = React.useState<number>(0);

  React.useEffect(() => {
    if (coupon && coupon.valid) {
      if (coupon.amount_off && selectedPlan?.price) {
        setTotalAmount(selectedPlan?.price - coupon.amount_off / 100);
      }
      if (coupon.percent_off && selectedPlan?.price) {
        setTotalAmount(
          selectedPlan?.price - (selectedPlan?.price * coupon.percent_off) / 100
        );
      }
    } else {
      setTotalAmount(selectedPlan?.price || 0);
    }

    return () => setTotalAmount(0);
  }, [coupon, selectedPlan?.price]);

  const classes = useStyles();
  return (
    <Box component="div" className={classes.orderContent}>
      <ListItem>
        <TextField
          margin="dense"
          label="Coupon Code"
          fullWidth
          onChange={(event) => setCouponCode(event.currentTarget.value)}
          className={classes.couponField}
        />
        <Button
          disableElevation
          color="primary"
          style={{ minWidth: 0 }}
          onClick={() => applyCoupon(couponCode)}>
          Apply
        </Button>
      </ListItem>
      {coupon && coupon.valid && (
        <ListItem>
          <ListItemText primary={coupon.name} />
          {coupon.amount_off && (
            <Typography
              variant="subtitle1"
              style={{ color: '#000', fontSize: '13px' }}>
              -{numeral(coupon.amount_off / 100).format('$0,0.00')}
            </Typography>
          )}
          {coupon.percent_off && selectedPlan?.price && (
            <Typography
              variant="subtitle1"
              style={{ color: '#000', fontSize: '13px' }}>
              -
              {numeral(selectedPlan?.price * (coupon.percent_off / 100)).format(
                '$0,0.00'
              )}
            </Typography>
          )}
        </ListItem>
      )}
      <Box component="div" className={classes.totalBox}>
        <Typography variant="caption" className={classes.totalBoxText}>
          Today&apos;s Total
        </Typography>
        <Typography variant="h5" className={classes.totalBoxTotal}>
          US {numeral(totalAmount).format('$0,0.00')}
        </Typography>
        <Typography variant="caption" className={classes.totalBoxSub}>
          {selectedPlan?.name && (
            <span>
              {selectedPlan?.message(numeral(totalAmount).format('$0,0.00'))}{' '}
              You may cancel at anytime in your payment settings. By clicking
              “Start Your Membership”, you agree to our{' '}
              <Link
                href="https://www.moneyprep.com/terms-and-conditions/"
                style={{ color: 'inherit', textDecoration: 'underline' }}>
                Terms and Conditions.
              </Link>
            </span>
          )}
        </Typography>
        <Button
          type="button"
          variant="contained"
          color="primary"
          size="small"
          className={classes.subButton}
          onClick={() => onConfirm(totalAmount)}
          disabled={app?.loading}>
          {app?.loading ? (
            <CircularProgress size="1.5rem" className={classes.loader} />
          ) : (
            'Start Membership'
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default OrderDetails;

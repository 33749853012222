import { handleAction, handleActions, combineActions } from 'redux-actions';
import reduceReducers from 'reduce-reducers';

import { Action, StripeState } from '../types';
import { findAsyncActions } from '../utils';
import { LOGOUT } from '../auth/auth.types';
import {
  CLOSE_NEW_SUB_MODAL,
  CLOSE_CHANGE_SUB_MODAL,
} from '../user/user.types';
import * as types from './stripe.types';

const initialState: StripeState = {
  status: 'pending',
  error: undefined,
  user: null,
  subscriptions: null,
  cards: [],
};

const requestReducer = handleAction(
  combineActions(...findAsyncActions(types, 'REQUEST')),
  (state: StripeState) => {
    return { ...state, error: undefined };
  },
  initialState
);

const errorReducer = handleAction(
  combineActions(...findAsyncActions(types, 'FAILURE')),
  (state: StripeState, action: Action<string>) => {
    return { ...state, status: 'failed', error: action.payload };
  },
  initialState
);

const successReducer = handleActions(
  {
    [LOGOUT.SUCCESS]: () => ({
      ...initialState,
    }),
    [types.STRIPE_GET_USER.SUCCESS]: (
      state: StripeState,
      action: Action<any>
    ) => {
      const user = action.payload?.user;
      const subscriptions = action.payload?.subscriptions;
      return {
        ...state,
        status: 'success',
        user,
        subscriptions,
      };
    },
    [types.STRIPE_PURCHASE_SUBSCRIPTION.SUCCESS]: (state: StripeState) => {
      return {
        ...state,
        status: 'success',
        coupon: undefined,
      };
    },
    [types.STRIPE_UPDATE_USER.SUCCESS]: (state: StripeState) => {
      return {
        ...state,
        status: 'success',
      };
    },
    [types.STRIPE_PURCHASE_SUBSCRIPTION.DONE]: (state: StripeState) => {
      return {
        ...state,
        status: 'done',
        coupon: undefined,
      };
    },
    [CLOSE_CHANGE_SUB_MODAL]: (state: StripeState) => {
      return {
        ...state,
        coupon: undefined,
        error: undefined,
      };
    },
    [CLOSE_NEW_SUB_MODAL]: (state: StripeState) => {
      return {
        ...state,
        coupon: undefined,
        error: undefined,
      };
    },
    [types.STRIPE_GET_CARDS.SUCCESS]: (
      state: StripeState,
      action: Action<any>
    ) => {
      return {
        ...state,
        cards: action.payload.cards.data,
        status: 'success',
      };
    },
    [types.STRIPE_GET_BILLING_PORTAL.SUCCESS]: (
      state: StripeState,
      action: Action<any>
    ) => {
      return {
        ...state,
        billingPortal: action.payload.session,
        status: 'success',
      };
    },
    [types.STRIPE_GET_COUPON_CODE_DETAILS.SUCCESS]: (
      state: StripeState,
      action: Action<any>
    ) => {
      return {
        ...state,
        coupon: action.payload.coupon,
        error: undefined,
        status: 'pending', // should not return success
      };
    },
  },
  initialState
);

export default reduceReducers(requestReducer, errorReducer, successReducer);

import { put, call, takeLatest } from 'redux-saga/effects';

import { Action } from '../types';
import wordpressBlogPosts from '../../services/wordpress';
import * as types from './wordpress.types';

function* getBlogPosts(
  action: Action<{
    path: string;
    params?: Record<string, string | number>;
  }>
) {
  try {
    const response = yield call(wordpressBlogPosts, action.payload!);
    yield put({ type: types.GET_BLOG_POSTS.SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: types.GET_BLOG_POSTS.FAILURE, payload: err.message });
  }
}

export default function* postsSaga() {
  yield takeLatest(types.GET_BLOG_POSTS.REQUEST, getBlogPosts);
}

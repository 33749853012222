import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_WORDPRESS_API_URL!,
});

const CancelToken = axios.CancelToken.source();

class axiosWordpressApi {
  static config() {
    return {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }

  static get(url: string, params: {}) {
    const header = this.config();
    const options = {
      mode: 'no-cors',
      ...header,
      params,
      CancelToken: CancelToken.token,
    };
    return axiosInstance.get(url, { ...options });
  }
}

export default axiosWordpressApi;

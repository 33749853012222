import { createAsyncActionTypes } from '../utils';

export const STRIPE_CREATE_USER_WITH_TOKEN = createAsyncActionTypes(
  'STRIPE_CREATE_USER_WITH_TOKEN'
);
export const STRIPE_GET_USER = createAsyncActionTypes('STRIPE_GET_USER');
export const STRIPE_GET_CARDS = createAsyncActionTypes('STRIPE_GET_CARDS');
export const STRIPE_CREATE_SOURCE = createAsyncActionTypes(
  'STRIPE_CREATE_SOURCE'
);
export const STRIPE_DELETE_SOURCE = createAsyncActionTypes(
  'STRIPE_DELETE_SOURCE'
);
export const STRIPE_PURCHASE_SUBSCRIPTION = createAsyncActionTypes(
  'STRIPE_PURCHASE_SUBSCRIPTION'
);
export const STRIPE_CANCEL_SUBSCRIPTION = createAsyncActionTypes(
  'STRIPE_CANCEL_SUBSCRIPTION'
);
export const STRIPE_GET_BILLING_PORTAL = createAsyncActionTypes(
  'STRIPE_GET_BILLING_PORTAL'
);
export const STRIPE_GET_COUPON_CODE_DETAILS = createAsyncActionTypes(
  'STRIPE_GET_COUPON_CODE_DETAILS'
);

export const STRIPE_UPDATE_USER = createAsyncActionTypes('STRIPE_UPDATE_USER');

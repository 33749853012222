import { createAsyncActionTypes } from '../utils';

export const GET_CHILD = createAsyncActionTypes('GET_CHILD');
export const GET_CHILDREN = createAsyncActionTypes('GET_CHILDREN');
export const CREATE_CHILD = createAsyncActionTypes('CREATE_CHILD');
export const LINK_CHILD = createAsyncActionTypes('LINK_CHILD');
export const CREATE_LINK_CHILD = createAsyncActionTypes('CREATE_LINK_CHILD');
export const UPDATE_CHILD = createAsyncActionTypes('UPDATE_CHILD');
export const DELETE_CHILD = createAsyncActionTypes('DELETE_CHILD');
export const UPDATE_CHILD_PASSWORD = createAsyncActionTypes(
  'UPDATE_CHILD_PASSWORD'
);
export const CHANGE_GRADE_LEVEL = createAsyncActionTypes('CHANGE_GRADE_LEVEL');

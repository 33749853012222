import { takeLatest } from 'redux-saga/effects';
import { Action } from '../types';
import * as types from './analytics.types';

function* dataLayerPush(action: Action<any>) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(action.payload);

  yield window.dataLayer;
}

export default function* analyticsSaga() {
  yield takeLatest(types.DATA_LAYER_PUSH, dataLayerPush);
}

import {
  collection,
  updateDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore';
import {
  onAuthStateChanged as onAuthStateChange,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  sendPasswordResetEmail,
  updateEmail as updateUserEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword as updateUserPassword,
  sendEmailVerification as sendUserEmailVerification,
  signInWithPopup,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  AdditionalUserInfo,
  OAuthProvider,
} from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import db, { auth, functions } from '../../config/firebase';
import { ParentSignUpRequest } from '../../types/user';

const timestamp = serverTimestamp();
const parentCollection = collection(db, 'parents');
const functionRef = httpsCallable(functions, 'parentSignUp');
const googleProviderRef = httpsCallable(functions, 'parentSignUpwithGoogle');
const getChildrenRef = httpsCallable(functions, 'getChildren');

/**
 * State change observer
 */
export const onAuthStateChanged = (successFn, failureFn) => {
  return onAuthStateChange(auth, (user) => {
    return user ? successFn(user) : failureFn();
  });
};

export const getChildren = async (uid: string) => {
  try {
    const response = await getChildrenRef({ uid });
    return response.data;
  } catch (error) {
    console.log('Signup err', error);
    throw error;
  }
};

/**
 * Sign in to firebase with email and password credential
 */
export const loginWithEmailAndPassword = ({ email, password }) => {
  const domain = email.split('@')[1];

  // moved domain to env*
  if (domain === process.env.EMAIL_DOMAIN!)
    throw new Error('Players cannot log into the dashboard.');

  return signInWithEmailAndPassword(auth, email, password)
    .then((res) => JSON.stringify(res))
    .then((res) => JSON.parse(res))
    .then(verifyRole)
    .then(({ token, role, uid }) => {
      return {
        token,
        role,
        uid,
      };
    })
    .catch((err) => {
      console.log('ERROR - sign in with email and password: ', err);
      switch (err.code) {
        case 'auth/invalid-email':
        case 'auth/user-disabled':
        case 'auth/user-not-found':
        case 'auth/wrong-password':
        default:
          throw err;
      }
    });
};

// /**
//  * Sign up with firebase with email and password credential
//  */
export const signUpWithEmailAndPassword = async (
  values: ParentSignUpRequest
) => {
  try {
    // create new user
    const { data } = await functionRef(values);

    // get token response
    const { token } = data as { token: string };

    // attempt sign in
    const { user } = await signInWithCustomToken(auth, token);

    return {
      ...user,
    };
  } catch (error) {
    console.log('Signup err', error);
    throw error;
  }
};

// /**
//  * Sign up with firebase with email and password credential
//  */
export const loginWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    const result = await signInWithPopup(auth, provider);
    const userInfo = getAdditionalUserInfo(result);

    // create new user
    const { uid } = result.user;
    const { isNewUser, profile } = userInfo as AdditionalUserInfo;
    const { email } = profile as Record<string, string>;
    if (isNewUser) {
      const { data } = await googleProviderRef({
        uid,
        email,
        country: null,
      });
      // get token response
      const { token } = data as { token: string };
      // attempt sign in
      const { user } = await signInWithCustomToken(auth, token);
      return {
        ...user,
      };
    }
    const { role, token, uid: userId } = await verifyRole(null);
    return {
      role,
      token,
      uid: userId,
    };
  } catch (error) {
    console.log('Signup err', error);
    switch (error.code) {
      case 'auth/invalid-email':
      case 'auth/user-disabled':
      case 'auth/user-not-found':
      default:
        throw error;
    }
  }
};

// /**
//  * Sign up or login with Apple
//  */
export const loginWithApple = async () => {
  try {
    const provider = new OAuthProvider('apple.com');
    // provider.addScope('profile');
    const result = await signInWithPopup(auth, provider);
    const userInfo = getAdditionalUserInfo(result);
    const { user: userData } = result;

    // console.log('USER: ', userData);
    // console.log('USERINFOR: ', userInfo);
    // console.log('INFO: ', userInfo);

    // create new user
    const { isNewUser } = userInfo as AdditionalUserInfo;
    if (isNewUser) {
      const { data } = await googleProviderRef({
        uid: userData.uid,
        email: userData.email,
        country: null,
      });
      // get token response
      const { token } = data as { token: string };
      // attempt sign in
      const { user } = await signInWithCustomToken(auth, token);
      return {
        ...user,
      };
    }
    const { role, token, uid: userId } = await verifyRole(null);
    return {
      role,
      token,
      uid: userId,
    };
  } catch (error) {
    console.log('Signup err', error);
    switch (error.code) {
      case 'auth/invalid-email':
      case 'auth/user-disabled':
      case 'auth/user-not-found':
      default:
        throw error;
    }
  }
};

/**
 * Forgot password
 */
export const forgotPassword = (data: { email: string }) => {
  return sendPasswordResetEmail(auth, data.email);
};

/**
 * Update email
 */
export const updateEmail = async (data: {
  password: string;
  email: string;
}) => {
  try {
    const { currentUser } = auth;
    const { email, password } = data;
    await reauthenticateUser(password);

    if (currentUser) {
      if (email) {
        await updateUserEmail(currentUser, email);
        const docRef = doc(parentCollection, currentUser.uid);
        const response = await updateDoc(docRef, {
          email,
          updatedAt: timestamp,
        });
        return response;
      }

      throw new Error('Missing parameter email.');
    }

    throw new Error('You are not signed in.');
  } catch (err) {
    console.log('updateEmail service error', err);
    throw err;
  }
};

/**
 * Add role
 */
export const addRole = async (data: { role: string }) => {
  try {
    if (!data || !data.role) throw new Error('No role');

    const response = await httpsCallable(functions, 'addRole')(data);
    return response;
  } catch (err) {
    console.log('addRole err', err);
    throw err;
  }
};

/**
 * Verify role
 */
export const verifyRole = async (tempRole: string | null) => {
  const { currentUser } = auth;

  if (currentUser) {
    const isAuth = await currentUser
      .getIdTokenResult(true)
      .then((idTokenResult) => {
        const { token, claims, signInProvider } = idTokenResult;

        if (claims?.role !== 'parent' && tempRole !== 'parent')
          throw new Error('Only parents can log into the dashboard.');

        return {
          token,
          signInProvider,
          role: claims.role,
          email: claims.email,
          uid: claims.user_id,
          tempRole,
        };
      })
      .catch((err) => {
        console.log('verifyRole error', err);
        throw err;
      });

    return isAuth;
  }

  return Promise.reject(new Error('You are not signed in.'));
};

/**
 * Reauthenticate user
 */
export const reauthenticateUser = async (password: string) => {
  const user = auth.currentUser;
  const credential = EmailAuthProvider.credential(user!.email!, password);
  const response = await reauthenticateWithCredential(user!, credential);
  return response;
};

/**
 * Update User Password
 */
export const updatePassword = async (data: {
  password: string;
  newPassword: string;
}) => {
  await reauthenticateUser(data.password);
  const user = auth.currentUser;
  const response = await updateUserPassword(user!, data.newPassword);
  return response;
};

/**
 * Check to see if user has a verified email
 */
export const verifyEmail = async () => {
  const user = auth.currentUser;
  if (user) {
    if (!user.emailVerified) {
      return Promise.reject(new Error('Email is unverified'));
    }
    return user.emailVerified;
  }
  return Promise.reject(new Error('You are not signed in.'));
};

/**
 * Send Email Verification
 */
export const sendEmailVerification = () => {
  const user = auth.currentUser;
  const response = sendUserEmailVerification(user!);
  return response;
};

/**
 * Sign out
 */
export const logout = () => {
  return auth.signOut();
};

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    borderRadius: 0,
    backdropFilter: 'blur(7px)',
    '& .MuiDialog-container': {
      [theme.breakpoints.down('xs')]: {
        height: '100vh',
      },
    },
    '& .MuiDialog-paperScrollPaper': {
      [theme.breakpoints.down('xs')]: {
        height: '100%',
        maxHeight: '100%',
      },
    },
  },
  paper: {
    padding: theme.spacing(4),
    outline: 'none',
    borderRadius: 0,
  },
}));

export default useStyles;

import { call, throttle, put, select } from 'redux-saga/effects';
import Swal from 'sweetalert2';

import { Action } from '../../types';
import * as Firebase from '../../../services/firebase';
import * as types from '../auth.types';
import { ParentSignUpRequest } from '../../../types/user';
import { getLocation } from '../auth.selectors';
import { dataLayerPush as dataLayerPushAction } from '../../analytics/analytics.actions';
import {
  setLoading as setLoadingAction,
  clearStorage as clearStorageAction,
} from '../../app/app.actions';

function* signUpWithEmailAndPassword(action: Action<ParentSignUpRequest>) {
  try {
    yield put(setLoadingAction(true));
    const response = yield call(
      Firebase.signUpWithEmailAndPassword,
      action.payload!
    );
    yield put({
      type: types.SIGNUP_WITH_EMAIL.SUCCESS,
      payload: response,
    });
    yield put({
      type: types.SEND_EMAIL_VERIFICATION.REQUEST,
    });
    // yield Swal.fire({
    //   icon: 'success',
    //   title:
    //     'Next, create a child account to access the fun money learning games!',
    //   timer: 2500,
    //   showConfirmButton: true,
    //   confirmButtonText: 'CONTINUE',
    // });
  } catch (err) {
    yield put(clearStorageAction(false));
    yield Swal.fire('Error', err.message, 'error');
    yield put({
      type: types.SIGNUP_WITH_EMAIL.FAILURE,
      payload: err.message,
    });
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* successHandler() {
  yield put(clearStorageAction(true));
  yield select(getLocation);

  yield put(
    dataLayerPushAction({
      event: 'parent_signup_validation',
    })
  );

  yield put(clearStorageAction(false));
}

export default function* signUpSaga() {
  yield throttle(
    1000,
    types.SIGNUP_WITH_EMAIL.REQUEST,
    signUpWithEmailAndPassword
  );
  yield throttle(1000, types.SIGNUP_WITH_EMAIL.SUCCESS, successHandler);
}

import { call, put, takeEvery } from 'redux-saga/effects';
import { GameState } from '../../types/gameState';
import { Action } from '../types';
import * as Firebase from '../../services/firebase';
import * as types from './gameState.types';

function* getGameState(action: Action<string>) {
  try {
    const result: GameState = yield call(
      Firebase.getGameState,
      action.payload!
    );
    yield put({
      type: types.GET_GAME_STATE.SUCCESS,
      payload: { result, playerId: action.payload! },
    });
  } catch (err) {
    yield put({
      type: types.GET_GAME_STATE.FAILURE,
      payload: err.message,
    });
  }
}

export default function* challengesSaga() {
  yield takeEvery(types.GET_GAME_STATE.REQUEST, getGameState);
}

import { handleActions } from 'redux-actions';
import reduceReducers from 'reduce-reducers';

import { Action, AppState } from '../types';
import { LOGOUT } from '../auth/auth.types';
import * as types from './app.types';

const initialState = {
  isInitialized: false,
  isOnboarded: false,
  loading: false,
  clearStorage: false,
};

const reducer = handleActions(
  {
    [types.SET_INITIALIZED]: (state: AppState, action: Action<boolean>) => {
      return {
        ...state,
        isInitialized: action.payload,
      };
    },
    [types.SET_LOADING]: (state: AppState, action: Action<boolean>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    [types.CLEAR_STORAGE]: (state: AppState, action: Action<boolean>) => {
      return {
        ...state,
        clearStorage: action.payload,
      };
    },
  },
  initialState
);

const successReducer = handleActions(
  {
    [LOGOUT.SUCCESS]: (state: AppState) => ({
      ...state,
    }),
  },
  initialState
);

export default reduceReducers(reducer, successReducer);

import { eventChannel } from 'redux-saga';
import {
  call,
  put,
  takeLatest,
  fork,
  take,
  cancel,
  cancelled,
  select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Swal from 'sweetalert2';

import { Child } from '../../types/user';
import { Action } from '../types';
import {
  setLoading as setLoadingAction,
  clearStorage as clearStorageAction,
} from '../app/app.actions';
import { LOGOUT } from '../auth/auth.types';
import * as Firebase from '../../services/firebase';
import {
  closeNewChildModal,
  // openPromoModal as openPromoModalAction,
} from '../user/user.actions';

import * as types from './child.types';
import { getCallback } from './child.selectors';

function* getChildren() {
  const observer = yield fork(observeChildren);
  yield take(LOGOUT.REQUEST);
  yield cancel(observer);
}

function* observeChildren() {
  const channel = eventChannel((emitter) => {
    Firebase.childrenObserver(emitter);
    return () => [];
  });

  while (true) {
    try {
      const response = yield take(channel);
      yield put({ type: types.GET_CHILDREN.SUCCESS, payload: response });
    } catch (err) {
      yield put({ type: types.GET_CHILDREN.FAILURE, payload: err.message });
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

function* getChild(action: Action<{ childUid: string }>) {
  try {
    const response = yield call(Firebase.getChild, action.payload);
    yield put({ type: types.GET_CHILD.SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: types.GET_CHILD.FAILURE, payload: err.message });
  }
}

function* createChild(action: Action<Child>) {
  try {
    yield put(setLoadingAction(true));
    const response = yield call(Firebase.createChild, action.payload);
    yield put({
      type: types.CREATE_CHILD.SUCCESS,
      payload: {
        ...response,
        showPromoModal: action?.payload?.showPromoModal,
        name: action?.payload?.name,
      },
    });
  } catch (err) {
    console.log(err);
    yield put({ type: types.CREATE_CHILD.FAILURE, payload: err.message });
  } finally {
    yield put(setLoadingAction(false));
    yield put(closeNewChildModal());
  }
}

function* createChildSuccess(action) {
  if (action?.payload?.showPromoModal) {
    yield put(push('/promo'));
  }
  // if (action?.payload?.showPromoModal)
  //   yield put(openPromoModalAction(action?.payload?.name));
  // else
  //   yield Swal.fire({
  //     icon: 'success',
  //     title: 'Your child account has been created.',
  //     // timer: 2500,
  //     showConfirmButton: true,
  //     confirmButtonText: 'GOT IT',
  //   });
}

// MG-799: Parent-Child A/B test flow
function* createLinkChild(action: Action<Child>) {
  try {
    yield put(setLoadingAction(true));
    if (action.payload?.studentId) {
      const response = yield call(Firebase.claimChild, action.payload);
      yield put({ type: types.CREATE_LINK_CHILD.SUCCESS, payload: response });
    } else {
      const response = yield call(Firebase.createChild, action.payload);
      yield put({ type: types.CREATE_LINK_CHILD.SUCCESS, payload: response });
    }
  } catch (err) {
    yield put(clearStorageAction(false));
    yield put({ type: types.CREATE_CHILD.FAILURE, payload: err.message });
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* createLinkChildSuccess() {
  const callBack = yield select(getCallback);
  yield Swal.fire({
    icon: 'success',
    title: 'Yay! Your kids can now enjoy MoneyPrep games from any desktop!',
    showConfirmButton: true,
    confirmButtonText: 'GOT IT',
    timer: 2000,
  });
  yield put(clearStorageAction(true));
  yield put(push(callBack));
}

function* linkChild(action: Action<Child>) {
  try {
    yield put(setLoadingAction(true));
    const response = yield call(Firebase.claimChild, action.payload);
    yield put({ type: types.LINK_CHILD.SUCCESS, payload: response });
    yield Swal.fire('Success', 'Child account successfully linked.', 'success');
  } catch (err) {
    yield put({ type: types.LINK_CHILD.FAILURE, payload: err.message });
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* deleteChild(action: Action<{ childUid: string }>) {
  try {
    yield put(setLoadingAction(true));
    const response = yield call(Firebase.deleteChild, action.payload);
    yield put({ type: types.DELETE_CHILD.SUCCESS, payload: response });
    yield Swal.fire(
      'Success',
      'Your child account has been deleted.',
      'success'
    );
  } catch (err) {
    yield put({ type: types.DELETE_CHILD.FAILURE, payload: err.message });
    yield Swal.fire('Error', err.message, 'error');
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* updateChild(action: Action<{ childUid: string }>) {
  try {
    yield put(setLoadingAction(false));
    yield call(Firebase.updateChild, action.payload);
    yield put({ type: types.UPDATE_CHILD.SUCCESS });
  } catch (err) {
    yield put({ type: types.UPDATE_CHILD.FAILURE, payload: err.message });
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* updateChildPassword(
  action: Action<{ uid: string; password: string }>
) {
  try {
    yield put(setLoadingAction(true));
    const response = yield call(Firebase.updateChildPassword, action.payload);
    yield put(setLoadingAction(false));
    yield put({ type: types.UPDATE_CHILD_PASSWORD.SUCCESS, payload: response });
    yield Swal.fire(
      'Success',
      "Your child's password has been updated",
      'success'
    );
  } catch (err) {
    yield put(setLoadingAction(false));
    yield put({
      type: types.UPDATE_CHILD_PASSWORD.FAILURE,
      payload: err.message,
    });
    yield Swal.fire('Error', err.message, 'error');
  }
}

function* changeChildGradeLevel(
  action: Action<{ playerId: string; newGradeLevel: number }>
) {
  try {
    yield put(setLoadingAction(true));
    const response = yield call(Firebase.changeChildGrade, action.payload);
    yield put({ type: types.CHANGE_GRADE_LEVEL.SUCCESS, payload: response });
    yield Swal.fire('Success', 'Your child grade has been changed.', 'success');
  } catch (err) {
    yield put({ type: types.CHANGE_GRADE_LEVEL.FAILURE, payload: err.message });
    yield Swal.fire('Error', err.message, 'error');
  } finally {
    yield put(setLoadingAction(false));
  }
}

export default function* childSaga() {
  yield takeLatest(types.GET_CHILDREN.REQUEST, getChildren);
  yield takeLatest(types.GET_CHILD.REQUEST, getChild);
  yield takeLatest(types.CREATE_CHILD.REQUEST, createChild);
  yield takeLatest(types.LINK_CHILD.REQUEST, linkChild);
  yield takeLatest(types.CREATE_LINK_CHILD.REQUEST, createLinkChild);
  yield takeLatest(types.CREATE_CHILD.SUCCESS, createChildSuccess);
  yield takeLatest(types.CREATE_LINK_CHILD.SUCCESS, createLinkChildSuccess);
  yield takeLatest(types.UPDATE_CHILD.REQUEST, updateChild);
  yield takeLatest(types.DELETE_CHILD.REQUEST, deleteChild);
  yield takeLatest(types.UPDATE_CHILD_PASSWORD.REQUEST, updateChildPassword);
  yield takeLatest(types.CHANGE_GRADE_LEVEL.REQUEST, changeChildGradeLevel);
}

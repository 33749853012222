import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  // Modal child styles
  root: {
    display: 'block',
    maxWidth: '500px',
    margin: 0,
    padding: 0,
  },
  dialogTitle: {
    display: 'block',
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  dialogTitleText: {
    display: 'block',
    position: 'relative',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '0 1rem',
    fontSize: '20px',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: '8px 0',
    color: '#fff',
  },
  container: {
    padding: '10px',
  },
  subText: {
    display: 'block',
    textAlign: 'center',
    maxWidth: '85%',
    margin: 'auto',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: 1.5,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  subText1: {
    display: 'block',
    textAlign: 'center',
    maxWidth: '85%',
    margin: 'auto',
    fontSize: '16px',
    color: '#009b1f',
    lineHeight: 2,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem',
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 8px',
    },
  },
  box: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    marginBottom: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  boxColored: {
    padding: '10px 14px',
    backgroundColor: '#b6e3f7',
  },
  header: {
    lineHeight: 1.8,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '16px',
    textTransform: 'uppercase',
    color: theme.palette.secondary.main,
  },
  header1: {
    lineHeight: 1.8,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '20px',
    textTransform: 'uppercase',
    color: '#000',
    margin: '6px 0',
  },
  gradeSelect: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0',
  },
  gradeSelectChild: {
    display: 'block',
    width: '100%',
    minWidth: '200px !important',
    '& .MuiOutlinedInput-input': {
      padding: '15px 0px',
      paddingLeft: '8px',
    },
  },
  styledButton: {
    margin: '1.5rem 0',
    padding: '.7rem 1.5rem',
    marginBottom: '0px',
    borderRadius: '25px',
    minWidth: '200px !important',
  },
}));

export default useStyles;

import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import { withMainLayout } from '../components/hoc';
import NotFoundRoute from './utils/NotFoundRoute';
import PrivateRoute from './utils/PrivateRoute';

const MainPage = lazy(() => import('../pages/MainPage'));

const MainpageRoutes = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/main" component={withMainLayout(MainPage)} />
      <NotFoundRoute />
    </Switch>
  );
};

export default MainpageRoutes;

import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#fdad3a',
      contrastText: '#000',
    },
    secondary: {
      main: '#0a2a66',
      contrastText: '#fff',
    },
    info: {
      main: '#0000ee',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'inherit',
  },
  overrides: {
    MuiDialog: {
      paper: {
        '&.MuiDialog-paper': {
          margin: '0px',
        },
        margin: 0,
      },
      paperWidthSm: {
        maxWidth: 'max-content',
      },
    },
    MuiButton: {
      root: {
        '&$disabled': {
          padding: '7px 3rem',
        },
      },
      containedPrimary: {
        padding: '7px 3rem',
      },
      text: {
        textTransform: 'none',
      },
      label: {
        textTransform: 'none',
      },
    },
    MuiCircularProgress: {
      root: {
        width: 'inherit',
      },
    },
    MuiGrid: {
      'spacing-xs-1': {
        '& > .MuiGrid-item': {
          padding: '0 4px',
        },
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '13px',
        marginBottom: '6px',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          display: 'none',
        },
      },
    },
  },
});

import { createActions } from 'redux-actions';

import * as types from './user.types';

export const {
  getUser,
  updateUser,
  openNewChildModal,
  openNewSubModal,
  openChangeSubModal,
  openLinkChildModal,
  openVideoModal,
  openOnboardingModal,
  openChangeGradeModal,
  openPromoModal,
  closeNewChildModal,
  closeNewSubModal,
  closeChangeSubModal,
  closeLinkChildModal,
  closeVideoModal,
  closeOnboardingModal,
  closeChangeGradeModal,
  closePromoModal,
} = createActions(
  {
    [types.UPDATE_USER.REQUEST]: [
      ({ data }) => ({ ...data }),
      ({ successPath, onboarding }) => ({ successPath, onboarding }),
    ],
  },
  types.GET_USER.REQUEST,
  types.OPEN_NEW_CHILD_MODAL,
  types.OPEN_NEW_SUB_MODAL,
  types.OPEN_CHANGE_SUB_MODAL,
  types.OPEN_LINK_CHILD_MODAL,
  types.OPEN_VIDEO_MODAL,
  types.OPEN_ONBOARDING_MODAL,
  types.OPEN_CHANGE_GRADE_MODAL,
  types.OPEN_PROMO_MODAL,
  types.CLOSE_NEW_CHILD_MODAL,
  types.CLOSE_NEW_SUB_MODAL,
  types.CLOSE_CHANGE_SUB_MODAL,
  types.CLOSE_LINK_CHILD_MODAL,
  types.CLOSE_VIDEO_MODAL,
  types.CLOSE_ONBOARDING_MODAL,
  types.CLOSE_CHANGE_GRADE_MODAL,
  types.CLOSE_PROMO_MODAL
);

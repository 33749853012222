// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import createRootReducer from './rootReducer';
import rootSaga from './rootSaga';

export const history = createBrowserHistory();
const rootReducer = createRootReducer(history);

const persistConfig = {
  key: 'primary',
  storage,
  whitelist: [],
  blacklist: ['app', 'auth', 'user'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = applyMiddleware(routerMiddleware(history), sagaMiddleware);
  const store = createStore(persistedReducer, composeWithDevTools(middleware));
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
}

export default configureStore();

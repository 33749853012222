import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import useStyles from './styles';

const CardForm: React.FC = () => {
  const classes = useStyles();
  return (
    <CardElement
      options={{
        style: {
          base: {
            fontSize: '16px',
            color: '#424770',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#9e2146',
          },
        },
      }}
      className={classes.cardElement}
    />
  );
};

export default CardForm;

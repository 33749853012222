import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  orderContent: {
    position: 'relative',
    display: 'block',
    width: '100%',
    // '& .MuiInputBase-input': {
    //   color: '#fff',
    // },
    // '& .MuiInputLabel-root': {
    //   color: theme.palette.primary.main,
    // },
  },
  couponField: {
    color: 'red',
  },
  subText: {
    display: 'block',
    textAlign: 'center',
    maxWidth: '85%',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'center',
    width: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: '0 8px',
      flexWrap: 'wrap',
    },
  },
  contentLeft: {
    position: 'relative',
    backgroundColor: '#f4f4f4',
    display: 'block',
    flex: 1,
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  formTab: {
    position: 'relative',
    display: 'block',
    width: '100%',
    margin: '10px 0',
  },
  planBox: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: '0 10px',
    marginBottom: '10px',
  },
  planTitle: {
    position: 'relative',
    display: 'block',
    fontWeight: 'bold',
    fontSize: '16px',
    margin: '10px 0',
  },
  planBoxContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  totalBox: {
    display: 'block',
    position: 'relative',
    backgroundColor: '#000',
    height: 'inherit',
    padding: '10px',
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
  },
  totalBoxText: {
    color: '#fff',
  },
  totalBoxTotal: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '20px',
  },
  totalBoxSub: {
    position: 'relative',
    display: 'block',
    color: '#fff',
    margin: '10px 0',
    textAlign: 'justify',
  },
  contentRight: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    height: 'inherit',
    borderRadius: '5px',
    border: '1px solid',
    padding: '1rem 5px',
    margin: '0 2px',
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px',
    margin: '.6rem 10px',
  },
  itemIcon: { width: '2em', height: '2em' },
  itemText: { textAlign: 'center', fontSize: '13px' },
  loader: {
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
  },
  subButton: {
    position: 'absolute',
    display: 'inline-block',
    top: 0,
    right: 0,
    color: '#000',
    margin: '10px',
    padding: '10px 2rem',
    textTransform: 'inherit',
    fontWeight: 'bolder',
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
    },
  },
}));

export default useStyles;

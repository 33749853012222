import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import { withSuspense } from '../components/hoc';
import NotFoundRoute from './utils/NotFoundRoute';
import PrivateRoute from './utils/PrivateRoute';

const MembershipPage = lazy(() => import('../pages/Membership'));

const MembershipRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path="/membership"
        component={withSuspense(MembershipPage)}
      />
      <NotFoundRoute />
    </Switch>
  );
};

export default MembershipRoutes;

import { createActions } from 'redux-actions';
import * as types from './app.types';

export const {
  setInitialized,
  setOnboarding,
  setLoading,
  clearStorage,
} = createActions(
  types.SET_INITIALIZED,
  types.SET_ONBOARDING,
  types.SET_LOADING,
  types.CLEAR_STORAGE
);

import React from 'react';
import { Box } from '@material-ui/core';
import ModalCard from '../../ui/ModalCard';
import ManageChildForm from '../../forms/ManageChildForm';
import useStyles from './styles';

interface NewChildModalProps {
  onClose: () => void;
}

const NewChildModal: React.FC<NewChildModalProps> = ({ onClose }) => {
  const classes = useStyles();

  return (
    <ModalCard onClose={onClose} title="Add a Child Account">
      <Box component="div" style={{ maxWidth: '420px', minWidth: '370px' }}>
        <Box component="div" className={classes.content}>
          <ManageChildForm />
        </Box>
      </Box>
    </ModalCard>
  );
};

export default NewChildModal;

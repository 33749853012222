import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'block',
    width: '100%',
  },
  cardElement: {
    position: 'relative',
    margin: '10px 0',
    borderRadius: '5px',
    border: '1px solid',
    padding: '10px 6px',
  },
}));

export default useStyles;

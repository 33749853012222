import React from 'react';
import { Formik, FormikProps } from 'formik';
import {
  TextField,
  Box,
  InputLabel,
  Select,
  FormHelperText,
} from '@material-ui/core';
import clx from 'clsx';
import LoadingButton from '../../ui/LoadingButton';
import { validator, createChildSchema } from '../../../validation';
import { AppState, ChildrenState } from '../../../redux/types';
// import FormikPersist from '../../lib/FormikPersist';
import useStyles from './styles';

interface FormValuesProps {
  name: string;
  gradeLevel: number;
  gender: 'male' | 'female' | undefined;
}

interface ManageChildFormProps {
  createChild: (payload: FormValuesProps) => void;
  app: AppState;
  childrenUsers: ChildrenState;
  showPromoModal?: boolean;
}

const ManageChildForm: React.FC<ManageChildFormProps> = ({
  app,
  childrenUsers,
  showPromoModal = false,
  createChild,
}) => {
  const classes = useStyles();
  const onSubmit = React.useCallback(
    (values: FormValuesProps) => {
      const { name, gender, ...rest } = values;

      const data = {
        name: name.trim(),
        gender,
        showPromoModal,
        ...rest,
      };
      createChild(data);
    },
    [createChild, showPromoModal]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: '',
        gradeLevel: 1,
        gender: undefined,
      }}
      onSubmit={onSubmit}
      validationSchema={() => validator(createChildSchema)}>
      {(props: FormikProps<FormValuesProps>) => {
        const {
          errors,
          handleChange,
          handleSubmit,
          touched,
          handleBlur,
          setFieldValue,
          values,
          isValid,
          dirty,
        } = props;
        const onInputBlur = (event: React.FocusEvent<any>) => {
          const value = (event.target.value || '').replace(/\s+/gi, '');
          setFieldValue(event.target.name, value.trim());
          handleBlur(event);
        };
        return (
          <form onSubmit={handleSubmit} style={{ padding: '12px 0' }}>
            <Box component="div" className={classes.formWrapper}>
              <InputLabel htmlFor="email" className={classes.label}>
                Child Name
              </InputLabel>
              <Box component="div" className={classes.formControl}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  placeholder="Enter child name...e.g James"
                  className={classes.formField}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={onInputBlur}
                  error={(errors.name && touched.name) || false}
                  helperText={touched.name && errors.name}
                />
              </Box>
              <InputLabel htmlFor="grade" className={classes.label}>
                Grade
              </InputLabel>
              <Box component="div" className={classes.formControl}>
                <Select
                  className={classes.formField}
                  native
                  id="gradeLevel"
                  name="gradeLevel"
                  value={values.gradeLevel}
                  onChange={handleChange('gradeLevel')}
                  onBlur={handleBlur}
                  error={(errors.gradeLevel && touched.gradeLevel) || false}>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                </Select>
                {touched.gradeLevel && errors.gradeLevel && (
                  <FormHelperText>
                    {touched.gradeLevel && errors.gradeLevel}
                  </FormHelperText>
                )}
              </Box>
              <InputLabel htmlFor="gender" className={classes.label}>
                Select Avatar
              </InputLabel>
              <Box component="div" className={classes.formControl1}>
                <InputLabel htmlFor="gender" className={classes.radiolabel}>
                  <input
                    type="radio"
                    name="gender"
                    value="male"
                    className={clx([classes.radioButton, classes.mega])}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>Mega</span>
                </InputLabel>
                <InputLabel htmlFor="gender" className={classes.radiolabel}>
                  <input
                    type="radio"
                    name="gender"
                    value="female"
                    className={clx([classes.radioButton, classes.moola])}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span>Moola</span>
                </InputLabel>
                {/* <Select
                  className={classes.formField}
                  native
                  id="gender"
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.gender && touched.gender) || false}>
                  <option value="male">Mega</option>
                  <option value="female">Moola</option>
                </Select> */}
                {touched.gender && errors.gender && (
                  <FormHelperText>
                    {touched.gender && errors.gender}
                  </FormHelperText>
                )}
              </Box>
            </Box>
            <Box component="div" className={classes.emailFooter}>
              <LoadingButton
                title="Create Account"
                isSubmitting={app.loading}
                isDisabled={!(isValid && dirty)}
              />
            </Box>
            {/* <FormikPersist
                    name="manage-child"
                    isSessionStorage
                    clearStorageOnLoad={false}
                  /> */}
          </form>
        );
      }}
    </Formik>
  );
};

export default ManageChildForm;

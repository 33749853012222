import { all, fork } from 'redux-saga/effects';

import authSaga from '../auth/auth.saga';
import userSaga from '../user/user.saga';
import childSaga from '../child/child.saga';
import stripeSaga from '../stripe/stripe.saga';
import challengesSaga from '../challenges/challenges.saga';
import gameStateSaga from '../gameState/gameState.saga';
import wordpressSaga from '../wordpress/wordpress.saga';
import analyticsSaga from '../analytics/analytics.saga';

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(userSaga),
    fork(childSaga),
    fork(stripeSaga),
    fork(challengesSaga),
    fork(gameStateSaga),
    fork(wordpressSaga),
    fork(analyticsSaga),
  ]);
}

import { httpsCallable } from 'firebase/functions';
import {
  collection,
  serverTimestamp,
  query,
  where,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  orderBy,
} from 'firebase/firestore';
import db, { functions, auth } from '../../config/firebase';

const timestamp = serverTimestamp();
const playerCollection = collection(db, 'players');
const childUsernameIndexCollection = collection(db, 'childUsernameIndex');

/**
 * Children observer
 */
export const childrenObserver = (emitter) => {
  const { currentUser } = auth;

  if (currentUser) {
    const { uid } = currentUser;
    const queryData = query(
      playerCollection,
      where('parent', '==', uid),
      orderBy('createdAt', 'desc')
    );

    return onSnapshot(
      queryData,
      (snapshot) => {
        if (!snapshot) return emitter([]);

        const children = snapshot.docs.map((child) => child.data());

        return emitter(children);
      },
      (err) => {
        console.log(err);
        return emitter(new Error(err.message));
      }
    );
  }

  return Promise.reject(new Error('You are not signed in.'));
};

/**
 * Get a child
 */
export const getChild = async (data) => {
  const { currentUser } = auth;

  if (currentUser) {
    const { childUid } = data;
    const docRef = doc(playerCollection, childUid);
    const child = await getDoc(docRef);
    return child.data();
  }

  return Promise.reject(new Error('You are not signed in.'));
};

/**
 * Create a child
 */
export const createChild = async (data) => {
  const { currentUser } = auth;

  if (currentUser) {
    if (!data.name) return Promise.reject(new Error('Missing username'));
    const response: any = await httpsCallable(
      functions,
      'createChild'
    )({
      ...data,
      email: `${data.name}@user.moneyprep.com`,
    });

    if (response.data.errorInfo) {
      if (response.data.errorInfo.code === 'auth/email-already-exists') {
        return Promise.reject(new Error('Username already exists'));
      }
      return Promise.reject(response.data.errorInfo);
    }

    return response;
  }

  return Promise.reject(new Error('You are not signed in.'));
};

/**
 * Claim a child
 */
export const claimChild = async (data) => {
  const { currentUser } = auth;

  if (currentUser) {
    if (!data.email) return Promise.reject(new Error('Missing email'));
    const response: any = await httpsCallable(
      functions,
      'claimChild'
    )({
      ...data,
      email: `${data.email}@user.moneyprep.com`,
    });

    if (response.data.errorInfo) {
      if (response.data.errorInfo.code === 'auth/email-already-exists') {
        return Promise.reject(new Error('Username already exists'));
      }
      return Promise.reject(response.data.errorInfo);
    }

    return response;
  }

  return Promise.reject(new Error('You are not signed in.'));
};

/**
 * Delete a child
 */
export const deleteChild = async (data) => {
  const { currentUser } = auth;

  if (currentUser) {
    const response: any = await httpsCallable(functions, 'deleteChild')(data);

    if (response.data.errorInfo) {
      return Promise.reject(response.data.errorInfo);
    }

    return response;
  }

  return Promise.reject(new Error('You are not signed in.'));
};

/**
 * Update a child
 */
export const updateChild = async (data) => {
  const { currentUser } = auth;

  if (currentUser) {
    const { childUid, ...rest } = data;
    const docRef = doc(playerCollection, childUid);
    const response = await updateDoc(docRef, { ...rest, updatedAt: timestamp });
    return response;
  }

  return Promise.reject(new Error('You are not signed in.'));
};

/**
 * update child grade
 */
export const changeChildGrade = async (data) => {
  const { currentUser } = auth;

  if (currentUser) {
    const response: any = await httpsCallable(
      functions,
      'changeGradeLevel'
    )(data);

    if (response.data.errorInfo) {
      throw new Error(response.data.errorInfo);
    }

    return response;
  }

  throw new Error('You are not signed in.');
};

/**
 * Check to see if user has any children
 */
export const verifyChildren = async () => {
  const { currentUser } = auth;

  if (currentUser) {
    const { uid } = currentUser;
    const queryData = query(playerCollection, where('parent', '==', uid));
    const childrenRef = await getDocs(queryData);
    if (childrenRef.docs.length === 0) {
      return Promise.reject(
        new Error("User doesn't have any children accounts")
      );
    }
    return childrenRef.docs.length > 0;
  }

  return Promise.reject(new Error('You are not signed in.'));
};

/**
 * Update child password
 */
export const updateChildPassword = async (data) => {
  const { currentUser } = auth;

  if (currentUser) {
    const response: any = await httpsCallable(
      functions,
      'updateChildPassword'
    )(data);

    if (response.data.errorInfo) {
      return Promise.reject(response.data.errorInfo);
    }

    return response;
  }

  return Promise.reject(new Error('You are not signed in.'));
};

/**
 * Check to see if username is taken
 */
export const verifyChildUsername = async ({
  username,
}: {
  username: string;
}) => {
  const queryData = query(
    childUsernameIndexCollection,
    where('username', '==', username)
  );
  const childrenRef = await getDocs(queryData);
  return childrenRef.docs.length === 0;
};

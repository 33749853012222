import { createActions } from 'redux-actions';

import * as types from './stripe.types';

export const {
  stripeCreateUserWithToken,
  stripeGetUser,
  stripeGetCards,
  stripeUpdateUser,
  stripeCreateSource,
  stripeDeleteSource,
  stripePurchaseSubscription,
  stripeCancelSubscription,
  stripeGetCouponCodeDetails,
} = createActions(
  types.STRIPE_CREATE_USER_WITH_TOKEN.REQUEST,
  types.STRIPE_GET_USER.REQUEST,
  types.STRIPE_GET_CARDS.REQUEST,
  types.STRIPE_UPDATE_USER.REQUEST,
  types.STRIPE_CREATE_SOURCE.REQUEST,
  types.STRIPE_DELETE_SOURCE.REQUEST,
  types.STRIPE_PURCHASE_SUBSCRIPTION.REQUEST,
  types.STRIPE_CANCEL_SUBSCRIPTION.REQUEST,
  types.STRIPE_GET_COUPON_CODE_DETAILS.REQUEST
);

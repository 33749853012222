import React from 'react';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, CssBaseline } from '@material-ui/core';

import { Provider as ReduxProvider, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import PopupProvider from './components/provider';

import Routes from './routes';
import theme from './themes';

import { configureStore, history } from './redux/store';

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

const { store, persistor } = configureStore;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

function App() {
  return (
    <ReduxProvider store={store} context={ReactReduxContext}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history} context={ReactReduxContext}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={3}>
                <CssBaseline />
                <Elements stripe={stripePromise}>
                  <PopupProvider>
                    <Routes />
                  </PopupProvider>
                </Elements>
              </SnackbarProvider>
            </ThemeProvider>
          </QueryParamProvider>
        </ConnectedRouter>
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;

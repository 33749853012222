import { connect } from 'react-redux';
import { ApplicationState } from '../../redux/types';
import {
  closeNewChildModal,
  closeNewSubModal,
  closeChangeSubModal,
  closeLinkChildModal,
  closeOnboardingModal,
  closeChangeGradeModal,
  openOnboardingModal,
  closePromoModal,
  openPromoModal,
} from '../../redux/user/user.actions';
import PopupProvider from './PopupProvider';

const mapState = ({ user, children, router }: ApplicationState) => ({
  user,
  router,
  childrenUsers: children,
  showNewSubModal: user?.showNewSubModal,
  showNewChildModal: user?.showNewChildModal,
  showChangeSubModal: user?.showChangeSubModal,
  showLinkChildModal: user?.showLinkChildModal,
  showOnboardingModal: user?.showOnboardingModal,
  showChangeGradeModal: user?.showChangeGradeModal,
  showPromoModal: user?.showPromoModal,
  showLoader: children.status === 'pending' || children.children.length === 0,
  isFailed: children.status === 'failed',
});

export default connect(mapState, {
  closeNewChildModal,
  closeNewSubModal,
  closeChangeSubModal,
  closeLinkChildModal,
  closeOnboardingModal,
  closeChangeGradeModal,
  openOnboardingModal,
  closePromoModal,
  openPromoModal,
})(PopupProvider);

import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';

import appReducer from '../app/app.reducer';
import authReducer from '../auth/auth.reducer';
import userReducer from '../user/user.reducer';
import childReducer from '../child/child.reducer';
import stripeReducer from '../stripe/stripe.reducer';
import challengesReducer from '../challenges/challenges.reducer';
import gameStateReducer from '../gameState/gameState.reducer';
import wordpressReducer from '../wordpress/wordpress.reducer';

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    auth: authReducer,
    user: userReducer,
    children: childReducer,
    stripe: stripeReducer,
    challenges: challengesReducer,
    gameState: gameStateReducer,
    blog: wordpressReducer,
  });

export default createRootReducer;

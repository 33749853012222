import React from 'react';
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import classnames from 'classnames';
import useStyles from './styles';

interface ModalCardProps {
  isOpen?: boolean;
  title?: string;
  canCloseModal?: boolean;
  onClose: () => void;
  hasColor?: boolean;
}

const ModalCard: React.FC<ModalCardProps> = ({
  isOpen,
  canCloseModal = true,
  title,
  children,
  hasColor,
  onClose,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <DialogTitle
        className={classnames(
          classes.dialogTitle,
          hasColor && classes.primaryBg
        )}>
        {title && (
          <Typography variant="caption" className={classes.dialogTitleText}>
            {title}
          </Typography>
        )}
        {canCloseModal && (
          <IconButton
            aria-label="close"
            className={classnames(
              classes.closeButton,
              hasColor && classes.closeButtonWhite
            )}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={classes.container}>{children}</DialogContent>
    </Paper>
  );
};

export default ModalCard;

import { collection, doc, getDoc } from 'firebase/firestore';
import db from '../../config/firebase';
import { GameState } from '../../types/gameState';

const gameStateCollection = collection(db, 'gameState');

// eslint-disable-next-line import/prefer-default-export
export const getGameState = async (playerId: string): Promise<GameState> => {
  const docRef = doc(gameStateCollection, playerId);
  const gameState = await getDoc(docRef);
  return gameState.data() as GameState;
};

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

import { AuthState, ApplicationState, ChildrenState } from '../../redux/types';

interface RootState {
  auth: AuthState;
  childrenUsers: ChildrenState;
}

const connector = connect(
  ({ auth, childrenUsers }: RootState) => ({ auth, childrenUsers }),
  {}
);

type PublicRouteProps = ConnectedProps<typeof connector> &
  RouteProps & {
    component: React.StatelessComponent | React.FC;
    componentProps?: any;
    auth: AuthState;
    childrenUsers: ChildrenState;
  };

const PublicRoute: React.FC<PublicRouteProps> = ({
  component,
  componentProps,
  auth,
  childrenUsers,
  ...rest
}) => {
  const location = useLocation();
  const { state } = location as any;
  const { from } = state || { from: { pathname: '/' } };
  const { search, pathname } = from;
  return (
    <Route
      {...rest}
      render={(props) => {
        return auth?.status !== 'success' ? (
          React.createElement(component, { ...props, ...componentProps })
        ) : (
          <Redirect
            to={{
              pathname:
                search !== '' && search
                  ? `${pathname}${search}`
                  : auth.callBack || from?.pathname || '/',
            }}
          />
        );
      }}
    />
  );
};

const mapState = ({ auth, children }: ApplicationState) => ({
  auth,
  childrenUsers: children,
});

export default connect(mapState, {})(PublicRoute);

import React, { lazy } from 'react';
import MpSuspense from '../ui/MpSuspense';

const MainLayout = lazy(() => import('../layout/MainLayout'));

export default function withMainLayout(WrappedComponent: React.FC) {
  return (props) => {
    return (
      <MpSuspense>
        <MainLayout>
          <WrappedComponent {...props} />
        </MainLayout>
      </MpSuspense>
    );
  };
}

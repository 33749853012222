import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import { withDashboard } from '../components/hoc';
import NotFoundRoute from './utils/NotFoundRoute';
import PrivateRoute from './utils/PrivateRoute';

const ManageChildrenPage = lazy(() => import('../pages/ManageChildren'));

const ManageChildrenRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/manage-children"
        component={withDashboard(ManageChildrenPage)}
      />
      <NotFoundRoute />
    </Switch>
  );
};

export default ManageChildrenRoutes;

import { createAsyncActionTypes } from '../utils';

export const LOGIN_WITH_EMAIL = createAsyncActionTypes('LOGIN_WITH_EMAIL');
export const LOGIN_WITH_GOOGLE = createAsyncActionTypes('LOGIN_WITH_GOOGLE');
export const LOGIN_WITH_APPLE = createAsyncActionTypes('LOGIN_WITH_APPLE');
export const SIGNUP_WITH_EMAIL = createAsyncActionTypes('SIGNUP_WITH_EMAIL');
export const RESET_PASSWORD = createAsyncActionTypes('RESET_PASSWORD');
export const UPDATE_PASSWORD = createAsyncActionTypes('UPDATE_PASSWORD');
export const UPDATE_EMAIL = createAsyncActionTypes('UPDATE_EMAIL');
export const SEND_EMAIL_VERIFICATION = createAsyncActionTypes(
  'SEND_EMAIL_VERIFICATION'
);
export const VERIFY_ROLE = createAsyncActionTypes('VERIFY_ROLE');
export const VERIFY_EMAIL = createAsyncActionTypes('VERIFY_EMAIL');
export const VERIFY_CHILDREN = createAsyncActionTypes('VERIFY_CHILDREN');
export const LOGOUT = createAsyncActionTypes('LOGOUT');

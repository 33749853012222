import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  // Modal child styles
  root: {
    display: 'block',
    minWidth: '400px',
  },
  label: {
    display: 'block',
    marginBottom: '4px',
    fontSize: '12px',
    color: 'rgba(55,53,47,0.65)',
  },
  formWrapper: {
    position: 'relative',
    width: '100%',
  },
  formControl: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    fontSize: '15px',
    lineHeight: '26px',
    position: 'relative',
    padding: '4px 10px',
    borderRadius: '3px',
    marginTop: '4px',
    marginBottom: '8px',
    boxShadow: 'rgb(15 15 15 / 5%) 0 1px inset',
    background: 'rgba(242,241,238,0.6)',
    border: '1px solid rgba(15,15,15, 0.15)',
  },
  formField: {
    display: 'block',
    resize: 'none',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    border: 'none',
    background: 'none',
    width: '100% !important',
    padding: 0,
  },
  emailFooter: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '5px 0',
  },
  linkText: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
  },
  link: {
    color: theme.palette.secondary.main,
  },
}));

export default useStyles;

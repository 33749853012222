import { throttle, put, call, select } from 'redux-saga/effects';
import Swal from 'sweetalert2';
// import { push } from 'connected-react-router';

import { Action } from '../../types';
import * as Firebase from '../../../services/firebase';
import { setLoading as setLoadingAction } from '../../app/app.actions';
import { getLocation } from '../auth.selectors';
import {
  LOGIN_WITH_APPLE,
  LOGIN_WITH_EMAIL,
  LOGIN_WITH_GOOGLE,
  VERIFY_ROLE,
} from '../auth.types';

function* loginWithEmailAndPassword(
  action: Action<{ email: string; password: string }>
) {
  try {
    yield put(setLoadingAction(true));
    const response = yield call(
      Firebase.loginWithEmailAndPassword,
      action.payload!
    );
    yield put({ type: LOGIN_WITH_EMAIL.SUCCESS, payload: response });
    yield put({ type: VERIFY_ROLE.REQUEST });
  } catch (err) {
    switch ((err as any).code) {
      case 'auth/user-not-found':
        yield Swal.fire(
          'Error',
          'Please sign up for a parent account.',
          'error'
        );
        break;
      case 'auth/wrong-password':
        yield Swal.fire(
          'Error',
          'There was a problem with your password.  Please try again.',
          'error'
        );
        break;
      default:
        yield Swal.fire('Error', err.message, 'error');
    }

    yield put({ type: LOGIN_WITH_EMAIL.FAILURE, payload: err.message });
  }

  // finally {
  //   yield put(setLoadingAction(false));
  // }
}

function* loginWithGoogle() {
  try {
    yield put(setLoadingAction(true));
    const response = yield call(Firebase.loginWithGoogle);
    yield put({ type: LOGIN_WITH_GOOGLE.SUCCESS, payload: response });
    yield put({ type: VERIFY_ROLE.REQUEST });
  } catch (err) {
    switch ((err as any).code) {
      case 'auth/user-not-found':
        yield Swal.fire(
          'Error',
          'Please sign up for a parent account.',
          'error'
        );
        break;
      case 'auth/wrong-password':
        yield Swal.fire(
          'Error',
          'There was a problem with your password.  Please try again.',
          'error'
        );
        break;
      default:
        yield Swal.fire('Error', err.message, 'error');
    }

    yield put({ type: LOGIN_WITH_GOOGLE.FAILURE, payload: err.message });
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* loginWithApple() {
  try {
    yield put(setLoadingAction(true));
    const response = yield call(Firebase.loginWithApple);
    yield put({ type: LOGIN_WITH_APPLE.SUCCESS, payload: response });
    yield put({ type: VERIFY_ROLE.REQUEST });
  } catch (err) {
    switch ((err as any).code) {
      case 'auth/user-not-found':
        yield Swal.fire(
          'Error',
          'Please sign up for a parent account.',
          'error'
        );
        break;
      case 'auth/wrong-password':
        yield Swal.fire(
          'Error',
          'There was a problem with your password.  Please try again.',
          'error'
        );
        break;
      default:
        yield Swal.fire('Error', err.message, 'error');
    }

    yield put({ type: LOGIN_WITH_APPLE.FAILURE, payload: err.message });
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* successHandler() {
  yield select(getLocation);
}

export default function* loginSaga() {
  yield throttle(1000, LOGIN_WITH_EMAIL.REQUEST, loginWithEmailAndPassword);
  yield throttle(1000, LOGIN_WITH_GOOGLE.REQUEST, loginWithGoogle);
  yield throttle(1000, LOGIN_WITH_APPLE.REQUEST, loginWithApple);
  yield throttle(1000, LOGIN_WITH_EMAIL.SUCCESS, successHandler);
}

import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import { withSuspense } from '../components/hoc';
import NotFoundRoute from './utils/NotFoundRoute';
import PrivateRoute from './utils/PrivateRoute';

const CheckoutPage = lazy(() => import('../pages/Checkout'));

const CheckoutRoutes = () => {
  return (
    <Switch>
      <PrivateRoute path="/checkout" component={withSuspense(CheckoutPage)} />
      <NotFoundRoute />
    </Switch>
  );
};

export default CheckoutRoutes;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    // gap: '5px',
  },
  planCard: {
    position: 'relative',
    display: 'block',
    padding: 0,
    margin: '5px 0',
    height: '80px',
  },
  selectedPlan: {
    backgroundColor: theme.palette.primary.main,
    '& #popular': {
      color: theme.palette.primary.contrastText,
    },
  },
  planCardContent: {
    display: 'block',
    position: 'relative',
    paddingBottom: 0,
    padding: '10px',
    margin: 0,
    width: '100%',
    height: '100%',
  },
  planCardHeader: {
    display: 'block',
    position: 'relative',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  planCardCaption: {
    display: 'block',
    position: 'relative',
    fontSize: '12px',
  },
  planCardPopular: {
    display: 'block',
    position: 'relative',
    fontWeight: 'bold',
    fontSize: '14px',
    color: theme.palette.primary.main,
    marginBottom: '3px',
  },
  chip: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  chipText: {
    fontSize: '11px',
    backgroundColor: '#eee',
    marginRight: '5px',
    padding: '1px 6px',
    fontWeight: 'bold',
  },
}));

export default useStyles;

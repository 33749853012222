import React, { lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { withSuspense } from '../components/hoc';
import PublicRoute from './utils/PublicRoute';

const RegisterPage = lazy(() => import('../pages/auth/SignUp'));
const SignInPage = lazy(() => import('../pages/auth/SignIn'));
const ForgotPasswordPage = lazy(() => import('../pages/auth/ForgotPassword'));

const RootRoutes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/main" />
      <PublicRoute path="/login" component={withSuspense(SignInPage)} />
      <PublicRoute path="/signup" component={withSuspense(RegisterPage)} />
      <PublicRoute
        path="/forgot-password"
        component={withSuspense(ForgotPasswordPage)}
      />
    </Switch>
  );
};

export default RootRoutes;

import React from 'react';

import { withSuspense } from '../components/hoc';

const UpdatePage = React.lazy(() => import('../pages/Onboarding'));
const ChildPage = React.lazy(() => import('../pages/Onboarding/ChildPage'));
const PromoPage = React.lazy(() => import('../pages/PromoPage'));
const RedirectPage = React.lazy(() => import('../pages/Redirect'));

export default {
  UpdateProfilePage: withSuspense(UpdatePage),
  CreateChildPage: withSuspense(ChildPage),
  PromoPages: withSuspense(PromoPage),
  RedirectRoute: withSuspense(RedirectPage),
};

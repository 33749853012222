import * as yup from 'yup';

const passwordRule = yup
  .string()
  .min(6, 'Minimum 6 characters.')
  .required('This field is required.');
const stringRule = yup
  .string()
  .strict(true)
  .trim('Do not include spaces at the beginning or end')
  .required('This field is required.');
const emailRule = yup
  .string()
  .strict(true)
  .trim('Do not include spaces at the beginning or end')
  .email('Please provide a valid email address.')
  .required('This field is required.');
const confirmPassword = yup
  .string()
  .oneOf([yup.ref('password'), undefined], 'Passwords must match.')
  .required('This field is required');
const gradeLevelRule = yup.number().required('This field is required.');

const usernameRule = yup
  .string()
  .matches(
    /^[^\s][A-Za-z0-9_.\s]*$/g,
    'Only letters and numbers are allowed for this field.'
  )
  .required('This field is required.');

export const LoginSchema = {
  email: emailRule,
  password: passwordRule,
};

export const SignupSchema = {
  firstName: stringRule.optional(),
  lastName: stringRule.optional(),
  email: emailRule,
  password: passwordRule,
  cpassword: confirmPassword,
  country: stringRule.optional(),
  acceptTerms: yup
    .bool()
    .oneOf([true], 'You need to accept terms and services')
    .required(),
};

export const forgotSchema = {
  email: emailRule,
};

export const createChildSchema = {
  studentId: yup.string().notRequired(),
  name: stringRule,
  gradeLevel: gradeLevelRule,
  gender: yup.string().required('This field is required.'),
};

export const linkChildSchema = {
  studentId: yup.string().required('This field is required.'),
  name: stringRule,
  gradeLevel: gradeLevelRule,
  childUsername: usernameRule,
  password: passwordRule,
  cpassword: confirmPassword,
};

export const promoCodeSchema = {
  promoCode: yup.string().required('This field is required.'),
};

export const validator = (schema: {}) => {
  return yup.object().shape({
    ...schema,
  });
};

import * as React from 'react';
import { FormikProps, connect } from 'formik';
import * as debounceFn from 'lodash.debounce';
import { AppState } from '../../../redux/types';

export interface FormikPersistProps {
  name: string;
  debounce?: number;
  isSessionStorage?: boolean;
  clearStorageOnLoad?: boolean;
  isModalType?: boolean;
  app?: AppState;
}

const FormikPersist: React.FC<
  FormikPersistProps & { formik: FormikProps<any> }
> = ({
  name,
  debounce: debounceValue,
  isSessionStorage,
  formik,
  clearStorageOnLoad = true,
  isModalType = true,
  app,
}) => {
  // Clear storage on mount
  React.useEffect(() => {
    if (clearStorageOnLoad) {
      if (isSessionStorage) {
        window.sessionStorage.removeItem(name);
      } else {
        window.localStorage.removeItem(name);
      }
    }
  }, [clearStorageOnLoad, isSessionStorage, name]);

  // if component is unmounted, clear the current opened form field
  React.useEffect(() => {
    return () => {
      if (isModalType) {
        window.sessionStorage.removeItem(name);
        window.localStorage.removeItem(name);
      }
    };
  }, [isModalType, name]);

  React.useEffect(() => {
    return () => {
      if (app?.clearStorage) {
        window.sessionStorage.removeItem(name);
        window.localStorage.removeItem(name);
      }
    };
  }, [name, app]);

  const saveForm = debounceFn((data: FormikProps<{}>) => {
    if (isSessionStorage) {
      window.sessionStorage.setItem(name, JSON.stringify(data));
    } else {
      window.localStorage.setItem(name, JSON.stringify(data));
    }
  }, debounceValue || 300);

  React.useEffect(() => {
    const possibleState = isSessionStorage
      ? window.sessionStorage.getItem(name)
      : window.localStorage.getItem(name);
    if (possibleState && possibleState !== null) {
      formik.setFormikState(JSON.parse(possibleState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    saveForm(formik);
    return () => {
      saveForm(null);
      window.sessionStorage.removeItem(name);
      window.localStorage.removeItem(name);
    };
  }, [saveForm, formik, name]);

  return null;
};

export default connect<FormikPersistProps, any>(FormikPersist);

import React, { lazy } from 'react';
import MpSuspense from '../ui/MpSuspense';

const DashboardLayout = lazy(() => import('../layout/DashboardLayout'));

export default function withDashboard(WrappedComponent: React.FC) {
  return (props) => {
    return (
      <MpSuspense>
        <DashboardLayout>
          <WrappedComponent {...props} />
        </DashboardLayout>
      </MpSuspense>
    );
  };
}

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouterState } from 'connected-react-router';
import { onAuthStateChanged } from '../services/firebase';
import { AppState, AuthState, UserState } from '../redux/types';
import LoadingScreen from '../components/ui/LoadingScreen';

import AppProvider from '../components/context';

import InternalRoutes from './links';

import PrivateRoute from './utils/PrivateRoute';

import RootRoutes from './RootRoutes';
import DashboardRoutes from './MainpageRoute';
import ManageChildrenRoutes from './ManageChildrenRoutes';
import OnboardingRoutes from './OnboardingRoutes';
import ProfileRoutes from './ProfileRoutes';
import CheckoutRoutes from './CheckoutRoutes';
import MembershipRoutes from './MembershipRoutes';

interface IndexRoutesProps {
  app: AppState;
  auth: AuthState;
  user: UserState;
  router: RouterState;
  verifyRole: () => void;
  logout: () => void;
}

const publicRoutes = ['/login', '/signup', '/forgot-password'];

const IndexRoutes: React.FC<IndexRoutesProps> = ({
  app,
  auth,
  user,
  router,
  verifyRole,
  logout,
}) => {
  const [ready, setReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (auth.status === 'pending' && !ready) {
      onAuthStateChanged(verifyRole, logout);
      setReady(true);
    }
  }, [verifyRole, logout, auth, ready]);

  if (
    auth.status === 'pending' &&
    !publicRoutes.includes(router.location.pathname)
  ) {
    return <LoadingScreen />;
  }

  return (
    <AppProvider>
      <Switch>
        <PrivateRoute
          path={InternalRoutes.PromoPage}
          component={OnboardingRoutes.PromoPages}
        />
        <PrivateRoute
          path={InternalRoutes.CreateChild}
          component={OnboardingRoutes.CreateChildPage}
        />
        <PrivateRoute
          path={InternalRoutes.UpdateProfile}
          component={OnboardingRoutes.UpdateProfilePage}
        />
        <PrivateRoute
          path={InternalRoutes.Redirect}
          component={OnboardingRoutes.RedirectRoute}
        />
        <Route path="/checkout" component={CheckoutRoutes} />
        <Route path="/membership" component={MembershipRoutes} />
        <Route path="/profile" component={ProfileRoutes} />
        <Route path="/manage-children" component={ManageChildrenRoutes} />
        <Route path="/main" component={DashboardRoutes} />
        <Route component={RootRoutes} />
      </Switch>
    </AppProvider>
  );
};

export default IndexRoutes;

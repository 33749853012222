import React from 'react';
import { Formik, FormikProps } from 'formik';
import {
  TextField,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Divider,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import LoadingButton from '../../ui/LoadingButton';
import { validator, linkChildSchema } from '../../../validation';
import { Action, AppState, ChildrenState } from '../../../redux/types';
import FormikPersist from '../../lib/FormikPersist';
import useStyles from './styles';

interface FormValuesProps {
  studentId: string;
  name: string;
  childUsername: string;
  gradeLevel: number;
  password: string;
  cpassword: string;
}

interface ChildProps {
  studentId: string;
  name: string;
  email: string;
  gradeLevel: number;
  password: string;
  cpassword?: string;
}

interface LinkChildFormProps {
  linkChild: (payload: ChildProps) => Action<ChildProps>;
  app: AppState;
  childrenUsers: ChildrenState;
}

const LinkChildForm: React.FC<LinkChildFormProps> = ({
  app,
  childrenUsers,
  linkChild,
}) => {
  const classes = useStyles();
  const onSubmit = React.useCallback(
    (values: FormValuesProps) => {
      const { name, childUsername, studentId, ...rest } = values;
      const data = {
        studentId: studentId.trim(),
        name: name.trim(),
        email: childUsername.trim(),
        ...rest,
      };
      linkChild(data);
    },
    [linkChild]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        studentId: '',
        name: '',
        childUsername: '',
        gradeLevel: 1,
        password: '',
        cpassword: '',
      }}
      onSubmit={onSubmit}
      validationSchema={() => validator(linkChildSchema)}>
      {(props: FormikProps<FormValuesProps>) => {
        const {
          errors,
          handleChange,
          handleSubmit,
          touched,
          handleBlur,
          setFieldValue,
          values,
          isValid,
          dirty,
        } = props;
        const onInputBlur = (event: React.FocusEvent<any>) => {
          const value = (event.target.value || '').replace(/\s+/gi, '');
          setFieldValue(event.target.name, value.trim());
          handleBlur(event);
        };
        return (
          <form
            className={classes.form}
            onSubmit={handleSubmit}
            style={{ padding: '12px 0' }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {childrenUsers.error && (
                  <Alert variant="filled" severity="error">
                    {childrenUsers.error}
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormHelperText className={classes.label}>
                  Link Student ID from School
                </FormHelperText>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="studentId"
                  label="Student ID *"
                  name="studentId"
                  autoFocus
                  className={classes.textField}
                  value={values.studentId}
                  onChange={handleChange}
                  onBlur={onInputBlur}
                  error={(errors.studentId && touched.studentId) || false}
                  helperText={touched.studentId && errors.studentId}
                />
              </Grid>
              <Divider className={classes.line} />

              <Grid item xs={12}>
                <FormHelperText className={classes.label}>
                  Child Details
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  label="First Name *"
                  name="name"
                  className={classes.textField2}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={onInputBlur}
                  error={(errors.name && touched.name) || false}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.special}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.textField2}>
                  <InputLabel htmlFor="gradeLevel">Grade Level *</InputLabel>
                  <Select
                    native
                    label="Grade Level *"
                    id="gradeLevel"
                    name="gradeLevel"
                    value={values.gradeLevel}
                    onChange={handleChange('gradeLevel')}
                    onBlur={handleBlur}
                    error={(errors.gradeLevel && touched.gradeLevel) || false}>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                  </Select>
                  {touched.gradeLevel && errors.gradeLevel && (
                    <FormHelperText>
                      {touched.gradeLevel && errors.gradeLevel}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Divider className={classes.line} />
              <Grid item xs={12}>
                <FormHelperText className={classes.label}>
                  Create Username and Password so kids can login to play!
                </FormHelperText>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="childUsername"
                  label="Child Username *"
                  name="childUsername"
                  className={classes.textField}
                  value={values.childUsername}
                  autoComplete="chrome-off"
                  onChange={handleChange}
                  onBlur={onInputBlur}
                  error={
                    (errors.childUsername && touched.childUsername) || false
                  }
                  helperText={touched.childUsername && errors.childUsername}
                />
                <FormHelperText
                  style={{
                    fontSize: '12px',
                    marginBottom: '4px',
                    marginTop: 0,
                  }}>
                  Only use letters & numbers - no spaces or special characters.
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="password"
                  label="Password *"
                  name="password"
                  type="password"
                  className={classes.textField}
                  value={values.password}
                  autoComplete="new-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.password && touched.password) || false}
                  helperText={touched.password && errors.password}
                />
                <FormHelperText style={{ fontSize: '12px' }}>
                  Minimum 6 characters.
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type="password"
                  id="cpassword"
                  label="Confirm Password *"
                  name="cpassword"
                  value={values.cpassword}
                  className={classes.textField}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={(errors.cpassword && touched.cpassword) || false}
                  helperText={touched.cpassword && errors.cpassword}
                />
              </Grid>
            </Grid>
            <Box component="div" className={classes.footer}>
              <LoadingButton
                title="Link Account"
                isSubmitting={app.loading}
                isDisabled={!(isValid && dirty)}
              />
            </Box>
            <FormikPersist
              name="link-child"
              isSessionStorage
              clearStorageOnLoad={false}
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default LinkChildForm;

import { connect } from 'react-redux';
import { ApplicationState } from '../../../redux/types';
import { changeGradeLevel } from '../../../redux/child/child.actions';
import ChangeGradeModal from './ChangeGradeModal';

const mapToState = ({ user, children, app }: ApplicationState) => ({
  app,
  user,
  childrenUsers: children,
});

export default connect(mapToState, { changeGradeLevel })(ChangeGradeModal);

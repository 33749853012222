import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ModalCard from '../../ui/ModalCard';
import LinkChildForm from '../../forms/LinkChildForm';
import useStyles from './styles';

interface LinkChildModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const LinkChildModal: React.FC<LinkChildModalProps> = ({ isOpen, onClose }) => {
  const classes = useStyles();

  return (
    <ModalCard isOpen={isOpen} onClose={onClose} title="Link Student ID">
      <Box component="div" style={{ maxWidth: '600px' }}>
        <Typography variant="subtitle1" className={classes.subText}>
          Link the Student ID received from school to your account.
        </Typography>
        <Box component="div" className={classes.content}>
          <LinkChildForm />
        </Box>
      </Box>
    </ModalCard>
  );
};

export default LinkChildModal;

import { connect } from 'react-redux';
import { ApplicationState } from '../../../redux/types';
import { createChild } from '../../../redux/child/child.actions';
import ManageChildForm from './ManageChildForm';

const mapState = ({ app, children }: ApplicationState) => ({
  app,
  childrenUsers: children,
});

export default connect(mapState, { createChild })(ManageChildForm);

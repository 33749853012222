import React, { DependencyList } from 'react';
import isEqual from 'lodash/isEqual';
import { useLocation } from 'react-router';

export const useDeepMemo = <T>(newValue: T) => {
  const value = React.useRef<T>(newValue);

  if (!isEqual(value, newValue)) {
    value.current = newValue;
  }

  return value.current;
};

export const useUpdateState = <T>(
  factory: () => T,
  deps?: DependencyList
): T => {
  const [state, setState] = React.useState(factory());

  React.useEffect(() => {
    setState(factory());
  }, [deps, factory]);

  return state;
};

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

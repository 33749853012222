import { call, put, takeEvery } from 'redux-saga/effects';
import { ChallengeResult } from '../../types/challenge';
import { Action } from '../types';
import * as Firebase from '../../services/firebase';
import * as types from './challenges.types';

function* getChallengeResults(action: Action<string>) {
  try {
    const results: ChallengeResult[] = yield call(
      Firebase.getChallengeResults,
      action.payload!
    );
    yield put({
      type: types.GET_CHALLENGE_RESULTS.SUCCESS,
      payload: { results, playerId: action.payload! },
    });
  } catch (err) {
    yield put({
      type: types.GET_CHALLENGE_RESULTS.FAILURE,
      payload: err.message,
    });
  }
}

export default function* challengesSaga() {
  yield takeEvery(types.GET_CHALLENGE_RESULTS.REQUEST, getChallengeResults);
}

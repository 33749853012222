import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  // Modal child styles
  subText: {
    display: 'block',
    textAlign: 'center',
    maxWidth: '85%',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  content: {
    display: 'block',
    position: 'relative',
    padding: '0 2rem',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '0 8px',
    },
  },
}));

export default useStyles;

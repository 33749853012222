import { httpsCallable } from 'firebase/functions';
import { functions } from '../../config/firebase';

async function createCustomer({ email }) {
  try {
    if (!email) throw new Error('No email');

    const response = await httpsCallable(
      functions,
      'stripeCreateUser'
    )({
      email,
    });

    stripeParseResponseForErrors(response);

    return response.data;
  } catch (err) {
    // Add specific error handling hooks here
    console.log(err);
    throw err;
  }
}

async function createCustomerWithToken({ email, tokenId }) {
  try {
    if (!email) throw new Error('No email');

    const response = await httpsCallable(
      functions,
      'stripeCreateUser'
    )({
      token: tokenId,
      email,
    });

    stripeParseResponseForErrors(response);

    return response.data;
  } catch (err) {
    // Add specific error handling hooks here
    console.log(err);
    throw err;
  }
}

async function updateCustomer({ customerId, firstName, lastName }) {
  try {
    if (!customerId) throw new Error('No stripeId');

    const response = await httpsCallable(
      functions,
      'updateStripeCustomer'
    )({
      customerId,
      firstName,
      lastName,
    });
    stripeParseResponseForErrors(response);
    return response.data;
  } catch (err) {
    // Add specific error handling hooks here
    console.log(err);
    throw err;
  }
}

async function getCustomer({ stripeId }) {
  try {
    if (!stripeId) throw new Error('No stripeId');

    const response = await httpsCallable(
      functions,
      'stripeGetUser'
    )({
      id: stripeId,
    });
    stripeParseResponseForErrors(response);
    return response.data;
  } catch (err) {
    // Add specific error handling hooks here
    console.log(err);
    throw err;
  }
}

async function getSubscriptions({ stripeId }) {
  try {
    if (!stripeId) throw new Error('No stripeId');

    const response = await httpsCallable(
      functions,
      'stripeGetSubscriptions'
    )({
      stripeId,
    });
    stripeParseResponseForErrors(response);
    return response.data;
  } catch (err) {
    // Add specific error handling hooks here
    console.log(err);
    throw err;
  }
}

async function getCards({ stripeId }) {
  try {
    if (!stripeId) throw new Error('No stripeId');

    const response = await httpsCallable(
      functions,
      'stripeGetCards'
    )({
      id: stripeId,
    });
    stripeParseResponseForErrors(response);
    return response.data;
  } catch (err) {
    // Add specific error handling hooks here
    console.log(err);
    throw err;
  }
}

async function createSource({ stripeId, tokenId }) {
  try {
    if (!stripeId) throw new Error('No stripeId');
    if (!tokenId) throw new Error('No tokenId');

    const response = await httpsCallable(
      functions,
      'stripeCreateSourceFromToken'
    )({
      id: stripeId,
      token: {
        id: tokenId,
      },
    });
    stripeParseResponseForErrors(response);
    return response.data;
  } catch (err) {
    // Add specific error handling hooks here
    console.log(err);
    throw err;
  }
}

async function deleteSource(action: {
  stripeId: string;
  tokenId: string;
}): Promise<any> {
  try {
    if (!action.tokenId) throw new Error('No tokenId');
    if (!action.stripeId) throw new Error('No customer ID');

    const response = await httpsCallable(
      functions,
      'stripeDeleteSource'
    )({
      id: action.stripeId,
      card: action.tokenId,
    });
    stripeParseResponseForErrors(response);
    return response.data;
  } catch (err) {
    // Add specific error handling hooks here
    console.log(err);
    throw err;
  }
}

async function createSourceFromPm({ stripeId, tokenId }) {
  try {
    if (!stripeId) throw new Error('No stripeId');
    if (!tokenId) throw new Error('No tokenId');

    const response = await httpsCallable(
      functions,
      'stripeCreateSourceFromPm'
    )({
      id: stripeId,
      token: {
        id: tokenId,
      },
    });

    stripeParseResponseForErrors(response);

    return response.data;
  } catch (err) {
    // Add specific error handling hooks here
    console.log(err);
    throw err;
  }
}

async function purchaseSubscription({
  stripeId,
  planId,
  childUid,
  couponCode,
  trialPeriod = 0,
}) {
  try {
    if (!stripeId) throw new Error('No stripeId');
    if (!planId) throw new Error('No planId');
    // if (!childUid) throw new Error('No childUid');

    const response = await httpsCallable(
      functions,
      'stripePurchaseSubscription'
    )({
      id: stripeId,
      plan: planId,
      child: childUid,
      couponCode,
      trialPeriod,
    });

    stripeParseResponseForErrors(response);

    return response.data;
  } catch (err) {
    // Add specific error handling hooks here
    console.log(err);
    throw err;
  }
}

async function cancelSubscription({ subId }) {
  try {
    if (!subId) throw new Error('No subId');

    const response = await httpsCallable(
      functions,
      'stripeCancelSubscription'
    )({
      id: subId,
    });

    stripeParseResponseForErrors(response);

    return response.data;
  } catch (err) {
    // Add specific error handling hooks here
    console.log(err);
    throw err;
  }
}

async function getBillingPortal({ stripeUserId, returnUrl }) {
  try {
    if (!stripeUserId) throw new Error('No subId');
    if (!returnUrl) throw new Error('No returnUrl');

    const response = await httpsCallable(
      functions,
      'stripeGetBillingPortal'
    )({
      stripeUserId,
      returnUrl,
    });

    stripeParseResponseForErrors(response);

    return response.data;
  } catch (err) {
    // Add specific error handling hooks here
    console.log(err);
    throw err;
  }
}

async function getCouponCodeDetails({ couponCode }) {
  try {
    if (!couponCode) throw new Error('No couponCode');

    const response = await httpsCallable(
      functions,
      'stripeGetCouponCodeDetails'
    )({
      couponCode,
    });

    stripeParseResponseForErrors(response);

    return response.data;
  } catch (err) {
    // Add specific error handling hooks here
    console.log(err);
    throw err;
  }
}

function stripeParseResponseForErrors(response) {
  if (response.data.code) {
    throw new Error(
      response.data?.raw?.message ||
        'An error occurred. Please try again or contact support.'
    );
  }
}

export {
  getCustomer,
  getCards,
  updateCustomer,
  createCustomer,
  createCustomerWithToken,
  createSource,
  deleteSource,
  createSourceFromPm,
  purchaseSubscription,
  cancelSubscription,
  getBillingPortal,
  getCouponCodeDetails,
  getSubscriptions,
};

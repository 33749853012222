import { eventChannel } from 'redux-saga';
import {
  call,
  put,
  fork,
  takeLatest,
  take,
  cancel,
  cancelled,
  select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { Action } from '../types';
import * as Firebase from '../../services/firebase';
import { setLoading as setLoadingAction } from '../app/app.actions';

import { LOGOUT } from '../auth/auth.types';
import { GET_BLOG_POSTS } from '../wordpress/wordpress.types';
import { STRIPE_GET_USER } from '../stripe/stripe.types';
// import { getPathname } from '../auth/auth.selectors';
import { dataLayerPush as dataLayerPushAction } from '../analytics/analytics.actions';
import { closePromoModal } from './user.actions';
import {
  checkNewUser,
  getChildren,
  getUser as getUserData,
} from './user.selectors';
import * as types from './user.types';

function* getUser() {
  const observer = yield fork(observeUser);
  yield take(LOGOUT.REQUEST);
  yield cancel(observer);
}

function* observeUser() {
  const channel = eventChannel((emitter) => {
    Firebase.userObserver(emitter);
    return () => {};
  });

  while (true) {
    try {
      // yield put(setInitializedAction(true));
      const response = yield take(channel);
      yield put({ type: types.GET_USER.SUCCESS, payload: response });
    } catch (err) {
      yield put({ type: types.GET_USER.FAILURE, payload: err.message });
    } finally {
      if (yield cancelled()) {
        channel.close();
      }

      yield put(setLoadingAction(false));
    }
  }
}

function* getUserSuccess(action: Action<{ stripeId: string }>) {
  // yield put(setInitializedAction(false));
  yield put({
    type: GET_BLOG_POSTS.REQUEST,
    payload: {
      path: '/posts',
      params: { per_page: 3, order: 'desc', categories: 33 },
    },
  });

  const hasChildren = yield select(getChildren);
  // const status = yield select(getAuthStatus);
  // const pathname = yield select(getPathname);
  const user = yield select(getUserData);
  const isNewUser = yield select(checkNewUser);
  // const isUserPremium = yield select(checkUserPremium);

  if (isNewUser) {
    yield put(push('/update-profile'));
  }

  if (!isNewUser && !hasChildren) {
    yield put(push('/child-profile'));
  }

  // if (
  //   !isNewUser &&
  //   hasChildren &&
  //   !user?.profile?.premium &&
  //   !user?.profile?.isProfileComplete
  // ) {
  //   yield put(openPromoModal());
  // }

  // if (!isNewUser && hasChildren && !isUserPremium) {
  //   yield put(push('/membership'));
  // }

  // if (!isNewUser && hasChildren && isUserPremium) {
  //   yield put(push(pathname));
  // }

  if (action.payload?.stripeId) {
    yield put({
      type: STRIPE_GET_USER.REQUEST,
      payload: { stripeId: action.payload.stripeId },
    });
  }

  yield put(
    dataLayerPushAction({
      event: 'accountLoad',
      uuid: user.uid,
      has_children: hasChildren,
    })
  );
}

function* updateUser(action) {
  try {
    yield put(setLoadingAction(true));
    yield call(Firebase.updateUser, action.payload);
    yield put({ type: types.UPDATE_USER.SUCCESS, payload: action?.meta });

    // if (action?.meta) {
    //   if (action.meta?.successPath) {
    //     yield put(push(action.meta.successPath));
    //   }
    // }
  } catch (err) {
    yield put({ type: types.UPDATE_USER.FAILURE, payload: err.message });
  } finally {
    yield put(setLoadingAction(false));
    yield put(closePromoModal());
  }
}

function* updateUserSuccess(action: Action<{ successPath: string }>) {
  if (action.payload?.successPath) {
    yield put(push(action.payload.successPath));
  }
}

export default function* userSaga() {
  yield takeLatest(types.GET_USER.REQUEST, getUser);
  yield takeLatest(types.GET_USER.SUCCESS, getUserSuccess);
  yield takeLatest(types.UPDATE_USER.REQUEST, updateUser);
  yield takeLatest(types.UPDATE_USER.SUCCESS, updateUserSuccess);
}

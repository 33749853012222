import {
  serverTimestamp,
  collection,
  doc,
  onSnapshot,
  setDoc,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import db, { auth, functions } from '../../config/firebase';

const timestamp = serverTimestamp();
const parentCollection = collection(db, 'parents');
const functionRef = httpsCallable(functions, 'verifyPromoCode');

/**
 * User observer
 */
export const userObserver = async (emitter) => {
  const { currentUser } = auth;

  if (currentUser) {
    const docRef = doc(parentCollection, currentUser.uid);
    return onSnapshot(
      docRef,
      (snapshot) => {
        if (!snapshot.exists) return emitter({});

        return emitter({
          id: currentUser.uid,
          ...currentUser.toJSON(),
          ...snapshot.data(),
        });
      },
      (err) => {
        console.log(err);
        return emitter(new Error(err.message));
      }
    );
  }

  return Promise.reject(new Error('You are not signed in.'));
};

/**
 * Create user
 */
export const createUser = async (userData) => {
  try {
    const { uid, firstName, lastName, country, name, email } = userData;
    const docRef = doc(parentCollection, uid);
    return await setDoc(docRef, {
      firstName,
      lastName,
      name,
      email,
      country,
      createdAt: timestamp,
      updatedAt: timestamp,
    });
  } catch (err: any) {
    console.log('User creation error', err);
    throw new Error(err.message);
  }
};

/**
 * Update user
 */
// export const updateUser = async (data) => {
//   const { currentUser } = auth;

//   if (currentUser) {
//     const docRef = doc(parentCollection, currentUser.uid);
//     const { role, ...rest } = data;
//     const response = await updateDoc(docRef, {
//       ...rest,
//       updated_at: timestamp,
//     });
//     return response;
//   }

//   return Promise.reject(new Error('You are not signed in.'));
// };

export const updateUser = async (data) => {
  const { currentUser } = auth;

  if (currentUser) {
    const response: any = await httpsCallable(
      functions,
      'updateParent'
    )({ uid: currentUser.uid, ...data });

    if (response.data.errorInfo) {
      return Promise.reject(response.data.errorInfo);
    }

    return response;
  }

  return Promise.reject(new Error('You are not signed in.'));
};

export const verifyPromoCode = async ({ uid, promoCode }) => {
  try {
    const { data } = await functionRef({ uid, promoCode });

    // get response
    const { code, type, durationInMonths, trialPeriod } = data as {
      code: string;
      durationInMonths: number;
      type: 'fixed' | 'trial';
      trialPeriod: number;
    };

    return {
      code,
      type,
      durationInMonths,
      trialPeriod,
    };
  } catch (error) {
    console.log('Code Error', error);
    throw error;
  }
};

import { handleAction, handleActions, combineActions } from 'redux-actions';
import reduceReducers from 'reduce-reducers';

import { ChallengeResult } from '../../types/challenge';
import { Action, ChallengesState } from '../types';
import { findAsyncActions } from '../utils';
import { LOGOUT } from '../auth/auth.types';
import * as types from './challenges.types';

const initialState: ChallengesState = {
  status: 'pending',
  error: undefined,
  results: {},
};

interface IChallengeResultsAction {
  results: ChallengeResult[];
  playerId: string;
}

const requestReducer = handleAction(
  combineActions(...findAsyncActions(types, 'REQUEST')),
  (state: ChallengesState) => {
    return state;
  },
  initialState
);

const errorReducer = handleAction(
  combineActions(...findAsyncActions(types, 'FAILURE')),
  (state: ChallengesState, action: Action<string>) => {
    return { ...state, status: 'failed', error: action.payload };
  },
  initialState
);

const successReducer = handleActions(
  {
    [LOGOUT.SUCCESS]: () => ({
      ...initialState,
    }),
    [types.GET_CHALLENGE_RESULTS.SUCCESS]: (
      state: ChallengesState,
      action: Action<IChallengeResultsAction>
    ) => {
      return {
        ...state,
        results: {
          ...state.results,
          [action.payload!.playerId]: action.payload!.results,
        },
        status: 'success',
      };
    },
  },
  initialState
);

export default reduceReducers(requestReducer, errorReducer, successReducer);

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: '36px',
    border: '1px solid rgba(15,15,15, 0.15)',
    width: '100%',
    boxShadow: 'rgb(15 15 15 / 5%) 0px 1px 2px',
    marginBottom: '4px',
    padding: '0 14px',
    whiteSpace: 'nowrap',
    userSelect: 'none',
    transition: 'background 20ms ease-in 0s',
    cursor: 'pointer',
    margin: '5px 0',
  },
  loader: {
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
  },
}));

export default useStyles;

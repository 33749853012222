import React from 'react';
import { useSnackbar } from 'notistack';
import { RouterState } from 'connected-react-router';
import { useHistory } from 'react-router';
import { PlanDetail, PLANS } from '../../config/plans';
import { ChildrenState, StripeState, UserState } from '../../redux/types';
import { useDeepMemo } from '../../utils/hooks';

const AppContext = React.createContext<any>({});

export const useAppContext = () => {
  const context = React.useContext(AppContext);
  if (context === undefined)
    throw new Error('useAppContext can only be used inside AppProvider');
  return context;
};

interface AppProviderProps {
  stripe: StripeState;
  user: UserState;
  kids: ChildrenState;
  router: RouterState;
  updateUser: (payload: { data: any; successPath?: string }) => void;
  openPromoModal: () => void;
  closePromoModal: () => void;
}

const whiteList = ['/checkout'];
const blackList = ['/main', '/', '/profile'];

export const AppProvider: React.FC<AppProviderProps> = ({
  children,
  stripe,
  user,
  kids,
  router,
  updateUser,
  openPromoModal,
  closePromoModal,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  // const location = useLocation();
  const history = useHistory();

  const selectedPlan = useDeepMemo<PlanDetail>(PLANS.Monthly);

  const isLoggedIn = user?.status === 'success';
  const isNewUser = user?.profile.isNewUser;
  const isUserPremium = user?.profile?.premium;
  const isProfileComplete = user?.profile?.isProfileComplete;
  const kidsLength = kids.children.length > 0;

  const [snackMessage, setSnackMessage] = React.useState<string | undefined>(
    undefined
  );

  React.useLayoutEffect(() => {
    if (
      isLoggedIn &&
      !isUserPremium &&
      blackList.includes(router.location.pathname)
    ) {
      history.replace('/checkout');
    }
  }, [history, isLoggedIn, isUserPremium, router.location.pathname]);

  React.useLayoutEffect(() => {
    if (
      isLoggedIn &&
      !isNewUser &&
      !isUserPremium &&
      !isProfileComplete &&
      kidsLength &&
      whiteList.includes(router.location.pathname)
    ) {
      openPromoModal();
    }
  }, [
    isLoggedIn,
    isNewUser,
    isUserPremium,
    isProfileComplete,
    kidsLength,
    openPromoModal,
    router.location.pathname,
  ]);

  const handleSetSnackMessage = React.useCallback(
    (message: string) => {
      setSnackMessage(message);
    },
    [setSnackMessage]
  );

  // enqueue snackbar
  React.useEffect(() => {
    if (stripe.error || snackMessage) {
      enqueueSnackbar(stripe.error || snackMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }
  }, [enqueueSnackbar, stripe.error, snackMessage]);

  const handleUserUpdate = React.useCallback(
    (data: any, path: string) => {
      updateUser({
        data: { ...data } || {},
        successPath: path,
      });
    },
    [updateUser]
  );

  // React.useEffect(() => {
  //   if (kids.status === 'submitted' && location.pathname === '/onboarding') {
  //     handleUserUpdate({ isNewUser: false }, '/membership');
  //   }
  // }, [kids.status, location.pathname, handleUserUpdate]);

  const contextValue = React.useMemo(
    () => ({
      selectedPlan,
      snackMessage,
      stripe,
      userProfile: { ...user.profile },
      handleUserUpdate,
      handleSetSnackMessage,
    }),
    [
      handleUserUpdate,
      handleSetSnackMessage,
      snackMessage,
      selectedPlan,
      user,
      stripe,
    ]
  );

  return (
    <AppContext.Provider value={contextValue!}>{children}</AppContext.Provider>
  );
};

import { all, fork, call, put, takeLatest, select } from 'redux-saga/effects';
import Swal from 'sweetalert2';
import { push } from 'connected-react-router';

import { Action } from '../types';
import * as Firebase from '../../services/firebase';
import { SET_INITIALIZED } from '../app/app.types';
import { GET_USER, UPDATE_USER } from '../user/user.types';
import { GET_CHILDREN } from '../child/child.types';
import { setLoading as setLoadingAction } from '../app/app.actions';
import * as types from './auth.types';
import loginSaga from './login/login.saga';
import signupSaga from './signup/signup.saga';
import {
  getAuthStatus,
  getPathname,
  getTempRoleSelector,
  getLocation,
  getSearchQuery,
} from './auth.selectors';

function* verifyRole() {
  try {
    const getTempRole = yield select(getTempRoleSelector);
    const response = yield call(Firebase.verifyRole, getTempRole);
    yield put({ type: types.VERIFY_ROLE.SUCCESS, payload: response });
    yield put({ type: GET_USER.REQUEST, payload: response.role });
  } catch (err) {
    yield put({ type: types.VERIFY_ROLE.FAILURE, payload: err.message });
  }
}

function* verifyRoleSuccess() {
  yield put({ type: GET_CHILDREN.REQUEST });
}

function* verifyEmail() {
  try {
    yield call(Firebase.verifyEmail);
    yield put({ type: types.VERIFY_EMAIL.SUCCESS });
  } catch (err) {
    yield put({ type: types.VERIFY_EMAIL.FAILURE, payload: err.message });
  }
}

function* verifyChildren() {
  const status = yield select(getAuthStatus);
  const pathname = yield select(getPathname);
  try {
    yield call(Firebase.verifyChildren);
    yield put({ type: types.VERIFY_CHILDREN.SUCCESS });
  } catch (err) {
    yield put({ type: types.VERIFY_CHILDREN.FAILURE, payload: err.message });
    if (
      status === 'success' &&
      (pathname === '/signup' || pathname === '/login')
    ) {
      yield put(push('/signup'));
    }
  }
}

function* resetPassword(action: Action<{ email: string }>) {
  try {
    yield put(setLoadingAction(true));
    const response = yield call(Firebase.forgotPassword, action.payload!);
    yield put({ type: types.RESET_PASSWORD.SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: types.RESET_PASSWORD.FAILURE, payload: err.message });
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* resetPasswordSuccess(action) {
  yield put(push('/login'));
  yield Swal.fire(
    'Success',
    'Please check your email for further instructions.',
    'success'
  );

  // yield put({ type: types.VERIFY_ROLE.REQUEST });
}

function* updatePassword(
  action: Action<{ password: string; newPassword: string }>
) {
  try {
    yield put(setLoadingAction(true));
    const response = yield call(Firebase.updatePassword, action.payload!);
    yield put({ type: types.UPDATE_PASSWORD.SUCCESS, payload: response });
    yield Swal.fire('Success', 'Your password has been updated.', 'success');
  } catch (err) {
    yield put({ type: types.UPDATE_PASSWORD.FAILURE, payload: err.message });
    yield Swal.fire('Error', err.message, 'error');
  } finally {
    yield put(setLoadingAction(false));
  }
}

function* updateEmail(action: Action<{ password: string; email: string }>) {
  try {
    yield call(Firebase.updateEmail, action.payload!);
    yield put({ type: types.UPDATE_EMAIL.SUCCESS });
    yield put({
      type: UPDATE_USER.REQUEST,
      payload: { data: { email: action.payload!.email } },
    });
    yield Swal.fire('Success', 'Your email has been updated.', 'success');
  } catch (err) {
    yield put({ type: types.UPDATE_EMAIL.FAILURE, payload: err.message });
    yield Swal.fire('Error', err.message, 'error');
  }
}

function* sendEmailVerification() {
  try {
    const response = yield call(Firebase.sendEmailVerification);
    yield put({
      type: types.SEND_EMAIL_VERIFICATION.SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield Swal.fire('Error', err.message, 'error');
  }
}

function* logout() {
  try {
    yield call(Firebase.logout);
    const location = yield select(getLocation);
    const search = yield select(getSearchQuery);
    if (location && search) {
      yield put({
        type: types.LOGOUT.SUCCESS,
        payload: `${location}${search}`,
      });
    } else {
      yield put({ type: types.LOGOUT.SUCCESS, payload: location });
    }
    yield put({ type: SET_INITIALIZED, payload: true });
  } catch (err) {
    yield put({ type: types.LOGOUT.FAILURE, payload: err.message });
  }
}

export default function* authSaga() {
  yield takeLatest(types.VERIFY_ROLE.REQUEST, verifyRole);
  yield takeLatest(types.VERIFY_ROLE.SUCCESS, verifyRoleSuccess);
  yield takeLatest(types.VERIFY_ROLE.FAILURE, logout);
  yield takeLatest(types.LOGOUT.REQUEST, logout);
  yield takeLatest(types.RESET_PASSWORD.REQUEST, resetPassword);
  yield takeLatest(types.RESET_PASSWORD.SUCCESS, resetPasswordSuccess);
  yield takeLatest(types.UPDATE_PASSWORD.REQUEST, updatePassword);
  yield takeLatest(types.UPDATE_EMAIL.REQUEST, updateEmail);
  yield takeLatest(types.VERIFY_EMAIL.REQUEST, verifyEmail);
  yield takeLatest(types.VERIFY_EMAIL.SUCCESS, verifyChildren);
  yield takeLatest(
    types.SEND_EMAIL_VERIFICATION.REQUEST,
    sendEmailVerification
  );
  yield all([fork(loginSaga), fork(signupSaga)]);
}

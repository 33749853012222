import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import { withMainLayout } from '../components/hoc';
import PrivateRoute from './utils/PrivateRoute';
import NotFoundRoute from './utils/NotFoundRoute';

const ProfilePage = lazy(() => import('../pages/Profile'));

const ProfileRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/profile"
        component={withMainLayout(ProfilePage)}
      />
      <NotFoundRoute />
    </Switch>
  );
};

export default ProfileRoutes;

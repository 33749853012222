import React from 'react';
import { Formik, FormikProps } from 'formik';
import {
  Box,
  Button,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core';
// import { useHistory } from 'react-router';
import { validator } from '../../../validation';
import ModalCard from '../../ui/ModalCard';
import LoadingButton from '../../ui/LoadingButton';
import { AppState, StripeState, UserState } from '../../../redux/types';
import { promoCodeSchema } from '../../../validation/validation';
import { updateChild, verifyPromoCode } from '../../../services/firebase';
// import createCustomerPromotion from '../../../services/revenuecat';
import { Child } from '../../../types/user';
import { useDeepMemo } from '../../../utils/hooks';

import useStyles from './styles';

interface FormValuesProps {
  promoCode: string;
}

interface PromoCodeModalProps {
  user: UserState;
  app: AppState;
  childData: Child;
  stripe: StripeState;
  isOpen: boolean;
  onClose: () => void;
  closePromoModal: () => void;
  updateUser: (payload: { data: any; successPath?: string }) => void;
}

const PromoCodeModal: React.FC<PromoCodeModalProps> = ({
  user,
  app,
  stripe,
  childData,
  isOpen,
  onClose,
  closePromoModal,
  updateUser,
}) => {
  const classes = useStyles();
  // const history = useHistory();
  const userId = useDeepMemo(user?.profile?.uid);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  const handleVerifyPromoCode = React.useCallback(
    async (values: FormValuesProps) => {
      try {
        setLoading(true);
        const { promoCode } = values;
        const { code, type, trialPeriod } = await verifyPromoCode({
          uid: userId,
          promoCode: promoCode.toLowerCase(),
        });
        const query = Buffer.from(
          JSON.stringify({
            trialPeriod,
            code,
          })
        ).toString('base64');
        if (type === 'trial') {
          updateUser({
            data: { promoCode },
            successPath: `/checkout?q=${query}`,
          });
        } else {
          await updateChild({
            childUid: childData.uid,
            premium: true,
          });
          updateUser({
            data: { isProfileComplete: true },
            successPath: `/redirect`,
          });
        }

        // if (valid && !redirect) {
        //   const entitle = createCustomerPromotion(userId);
        //   await Promise.all([entitle]).catch(() => setLoading(false));
        //   updateUser({
        //     data: { promoCode, premium: true, isProfileComplete: true },
        //     successPath: '/redirect',
        //   });
        //   await updateChild({
        //     childUid: childData.uid,
        //     premium: true,
        //   });
        //   setLoading(false);
        // }
      } catch (err) {
        setError(`${(err as Error).message}`);
      } finally {
        setLoading(false);
      }
    },
    [userId, updateUser, childData]
  );

  const handleRedirect = React.useCallback(() => {
    closePromoModal();
    // history.push('/promo');
  }, [closePromoModal]);

  return (
    <ModalCard
      canCloseModal={false}
      isOpen={isOpen}
      onClose={onClose}
      title="Have a Promo Code?">
      <Box className={classes.root}>
        <Formik
          enableReinitialize
          initialValues={{
            promoCode: '',
          }}
          onSubmit={handleVerifyPromoCode}
          validationSchema={() => validator(promoCodeSchema)}>
          {(props: FormikProps<FormValuesProps>) => {
            const {
              errors,
              handleChange,
              handleSubmit,
              touched,
              handleBlur,
              setFieldValue,
              values,
              isValid,
              dirty,
            } = props;
            const onInputBlur = (event: React.FocusEvent<any>) => {
              const value = (event.target.value || '').replace(/\s+/gi, '');
              setFieldValue(event.target.name, value.trim());
              handleBlur(event);
            };
            return (
              <form onSubmit={handleSubmit} style={{ padding: '12px 0' }}>
                <Box component="div" className={classes.formWrapper}>
                  {error && (
                    <Typography
                      variant="subtitle1"
                      style={{ fontSize: '12px', color: 'red' }}>
                      {error}
                    </Typography>
                  )}
                  <InputLabel htmlFor="email" className={classes.label}>
                    Promo Code
                  </InputLabel>
                  <Box component="div" className={classes.formControl}>
                    <TextField
                      fullWidth
                      id="promoCode"
                      name="promoCode"
                      placeholder="Enter promo code"
                      className={classes.formField}
                      value={values.promoCode}
                      onChange={handleChange}
                      onBlur={onInputBlur}
                      error={(errors.promoCode && touched.promoCode) || false}
                      helperText={touched.promoCode && errors.promoCode}
                    />
                  </Box>
                </Box>
                <Box component="div" className={classes.emailFooter}>
                  <LoadingButton
                    title="Claim"
                    isSubmitting={app.loading || loading}
                    isDisabled={!(isValid && dirty)}
                  />
                </Box>
              </form>
            );
          }}
        </Formik>
        <Box component="div" className={classes.emailFooter}>
          <Typography variant="subtitle2" className={classes.linkText}>
            Don&apos;t have promo code?{' '}
            <Button onClick={handleRedirect} className={classes.link}>
              <Typography variant="subtitle2">Continue</Typography>
            </Button>
          </Typography>
        </Box>
      </Box>
    </ModalCard>
  );
};

export default PromoCodeModal;

import { createAsyncActionTypes } from '../utils';

export const GET_USER = createAsyncActionTypes('GET_USER');
export const UPDATE_USER = createAsyncActionTypes('UPDATE_USER');

// modal controls
export const OPEN_NEW_CHILD_MODAL = 'OPEN_NEW_CHILD_MODAL';
export const CLOSE_NEW_CHILD_MODAL = 'CLOSE_NEW_CHILD_MODAL';

export const OPEN_NEW_SUB_MODAL = 'OPEN_NEW_SUB_MODAL';
export const CLOSE_NEW_SUB_MODAL = 'CLOSE_NEW_SUB_MODAL';

export const OPEN_CHANGE_SUB_MODAL = 'OPEN_CHANGE_SUB_MODAL';
export const CLOSE_CHANGE_SUB_MODAL = 'CLOSE_CHANGE_SUB_MODAL';

export const OPEN_LINK_CHILD_MODAL = 'OPEN_LINK_CHILD_MODAL';
export const CLOSE_LINK_CHILD_MODAL = 'CLOSE_LINK_CHILD_MODAL';

export const OPEN_VIDEO_MODAL = 'OPEN_VIDEO_MODAL';
export const CLOSE_VIDEO_MODAL = 'CLOSE_VIDEO_MODAL';

export const OPEN_ONBOARDING_MODAL = 'OPEN_ONBOARDING_MODAL';
export const CLOSE_ONBOARDING_MODAL = 'CLOSE_ONBOARDING_MODAL';

export const OPEN_CHANGE_GRADE_MODAL = 'OPEN_CHANGE_GRADE_MODAL';
export const CLOSE_CHANGE_GRADE_MODAL = 'CLOSE_CHANGE_GRADE_MODAL';

export const OPEN_PROMO_MODAL = 'OPEN_PROMO_MODAL';
export const CLOSE_PROMO_MODAL = 'CLOSE_PROMO_MODAL';

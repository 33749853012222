import { handleAction, handleActions, combineActions } from 'redux-actions';
import reduceReducers from 'reduce-reducers';

import { GameState } from '../../types/gameState';
import { Action, GameStateState } from '../types';
import { findAsyncActions } from '../utils';
import { LOGOUT } from '../auth/auth.types';
import * as types from './gameState.types';

const initialState: GameStateState = {
  status: 'pending',
  error: undefined,
  results: {},
};

interface IGameStateAction {
  result: GameState;
  playerId: string;
}

const requestReducer = handleAction(
  combineActions(...findAsyncActions(types, 'REQUEST')),
  (state: GameStateState) => {
    return state;
  },
  initialState
);

const errorReducer = handleAction(
  combineActions(...findAsyncActions(types, 'FAILURE')),
  (state: GameStateState, action: Action<string>) => {
    return { ...state, status: 'failed', error: action.payload };
  },
  initialState
);

const successReducer = handleActions(
  {
    [LOGOUT.SUCCESS]: () => ({
      ...initialState,
    }),
    [types.GET_GAME_STATE.SUCCESS]: (
      state: GameStateState,
      action: Action<IGameStateAction>
    ) => {
      return {
        ...state,
        results: {
          ...state.results,
          [action.payload!.playerId]: action.payload!.result,
        },
        status: 'success',
      };
    },
  },
  initialState
);

export default reduceReducers(requestReducer, errorReducer, successReducer);

const InternalRoutes = {
  Dashboard: '/',
  Login: '/login',
  Register: '/signup',
  ForgotPassword: '/forgot-password',
  ManageChildren: '/manage-children',
  Membership: '/membership',
  Checkout: '/checkout',
  UpdateProfile: '/update-profile',
  CreateChild: '/child-profile',
  PromoPage: '/promo',
  Redirect: '/redirect',
  Notfound: '*',
  Profile: (id: string) => `/profile/${id}`,
};

export default InternalRoutes;

import React from 'react';
import { RouterState } from 'connected-react-router';
import { Backdrop, Fade, Dialog } from '@material-ui/core';
import { ChildrenState, UserState } from '../../redux/types';
import NewChildModal from '../modals/NewChildModal';
import NewSubscriptionModal from '../modals/NewSubscriptionModal';
import LinkChildModal from '../modals/LinkChildModal';
import PromoCodeModal from '../modals/PromoCodeModal';
// import OnboardingModal from '../modals/OnboardingModal';
import ChangeGradeModal from '../modals/ChangeGradeModal';
// import PromopageModal from '../modals/PromopageModal';
import useStyles from './styles';

type ModalType =
  | 'newchild'
  | 'newsub'
  | 'changesub'
  | 'linkchild'
  | 'loader'
  | 'onboarding'
  | 'change-grade'
  | 'promo'
  | undefined;

interface PopupProviderProps {
  user: UserState;
  router: RouterState;
  childrenUsers: ChildrenState;
  showNewSubModal?: boolean;
  showNewChildModal?: boolean;
  showChangeSubModal?: boolean;
  showLinkChildModal?: boolean;
  showLoader?: boolean;
  showOnboardingModal?: boolean;
  showChangeGradeModal?: boolean;
  showPromoModal?: boolean;
  isFailed?: boolean;
  closeNewChildModal: () => void;
  closeNewSubModal: () => void;
  closeChangeSubModal: () => void;
  closeLinkChildModal: () => void;
  closeOnboardingModal: () => void;
  closeChangeGradeModal: () => void;
  closePromoModal: () => void;
  openPromoModal: () => void;
  openOnboardingModal: () => void;
}

const PopupProvider: React.FC<PopupProviderProps> = ({
  user,
  router,
  childrenUsers,
  children,
  showNewChildModal,
  showNewSubModal,
  showChangeSubModal,
  showLinkChildModal,
  showOnboardingModal,
  showChangeGradeModal,
  showLoader,
  showPromoModal,
  isFailed,
  closeNewChildModal,
  closeNewSubModal,
  closeChangeSubModal,
  closeLinkChildModal,
  closeOnboardingModal,
  closeChangeGradeModal,
  closePromoModal,
  openPromoModal,
  openOnboardingModal,
}) => {
  const classes = useStyles();
  // const isUserOnboarded = window.localStorage.getItem('onboarded');

  const [showModal, setShowModal] = React.useState<ModalType>(undefined);

  React.useEffect(() => {
    if (showNewChildModal) {
      setShowModal('newchild');
    } else if (showNewSubModal) {
      setShowModal('newsub');
    } else if (showChangeSubModal) {
      setShowModal('changesub');
    } else if (showLinkChildModal) {
      setShowModal('linkchild');
    } else if (showOnboardingModal) {
      setShowModal('onboarding');
    } else if (showChangeGradeModal) {
      setShowModal('change-grade');
    } else if (showPromoModal) {
      setShowModal('promo');
    } else setShowModal(undefined);
  }, [
    showChangeGradeModal,
    showChangeSubModal,
    showLinkChildModal,
    showNewChildModal,
    showNewSubModal,
    showOnboardingModal,
    showPromoModal,
  ]);

  // React.useEffect(() => {
  //   const data = JSON.parse(isUserOnboarded!);
  //   if (
  //     !data?.onboarded &&
  //     !showLoader &&
  //     ![
  //       '/signup',
  //       '/login',
  //       '/child-profile',
  //       '/update-profile',
  //       '/membership',
  //       '/checkout',
  //     ].includes(router.location.pathname)
  //   ) {
  //     openOnboardingModal();
  //   }
  //   if (!showLoader && data?.onboarded) {
  //     setShowModal(undefined);
  //   }
  // }, [
  //   showLoader,
  //   setShowModal,
  //   isUserOnboarded,
  //   openOnboardingModal,
  //   router.location.pathname,
  // ]);

  React.useEffect(() => {
    if (isFailed) setShowModal(showModal);
  }, [setShowModal, isFailed, showModal]);

  const closeModal = React.useCallback(() => {
    if (showNewSubModal) closeNewSubModal();
    else if (showNewChildModal) closeNewChildModal();
    else if (showChangeSubModal) closeChangeSubModal();
    else if (showLinkChildModal) closeLinkChildModal();
    else if (showOnboardingModal) closeOnboardingModal();
    else if (showChangeGradeModal) closeChangeGradeModal();
    setShowModal(undefined);
  }, [
    closeChangeGradeModal,
    closeChangeSubModal,
    closeLinkChildModal,
    closeNewChildModal,
    closeNewSubModal,
    closeOnboardingModal,
    showChangeGradeModal,
    showChangeSubModal,
    showLinkChildModal,
    showNewChildModal,
    showNewSubModal,
    showOnboardingModal,
  ]);

  const showActiveModal = React.useMemo(() => {
    switch (showModal) {
      case 'newchild':
        return <NewChildModal onClose={closeModal} />;
      case 'newsub':
        return (
          <NewSubscriptionModal
            isOpen={!!showModal}
            onClose={closeModal}
            childUid={user?.childUid || ''}
          />
        );
      case 'linkchild':
        return <LinkChildModal isOpen={!!showModal} onClose={closeModal} />;
      // case 'onboarding':
      //   return <OnboardingModal onClose={closeModal} />;
      case 'change-grade':
        return (
          <ChangeGradeModal childUid={user?.childUid} onClose={closeModal} />
        );
      case 'promo':
        return <PromoCodeModal isOpen={!!showModal} onClose={closeModal} />;
      default:
        return null;
    }
  }, [closeModal, showModal, user?.childUid]);

  return (
    <>
      {children}
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={!!showModal}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableBackdropClick={
          showModal === 'onboarding' ||
          showModal === 'promo' ||
          showModal === 'changesub'
        }>
        {showActiveModal && <Fade in={!!showModal}>{showActiveModal}</Fade>}
      </Dialog>
    </>
  );
};

export default PopupProvider;

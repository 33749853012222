import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

import { AuthState, ApplicationState } from '../../redux/types';

interface RootState {
  auth: AuthState;
}

const connector = connect(({ auth }: RootState) => ({ auth }), {});

type PrivateRouteProps = ConnectedProps<typeof connector> &
  RouteProps & {
    component: React.StatelessComponent | React.FC;
    componentProps?: any;
    auth: AuthState;
  };

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component,
  componentProps,
  auth,
  ...rest
}) => {
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        return auth?.status === 'success' ? (
          React.createElement(component, { ...props, ...componentProps })
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

const mapState = ({ auth }: ApplicationState) => ({ auth });

export default connect(mapState, {})(PrivateRoute);

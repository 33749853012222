import { connect } from 'react-redux';
import { ApplicationState } from '../../../redux/types';
import { updateUser, closePromoModal } from '../../../redux/user/user.actions';
import PromoCodeModal from './PromoCodeModal';

const mapState = ({
  stripe,
  app,
  user,
  children: result,
}: ApplicationState) => ({
  app,
  stripe,
  user,
  childData: result.children[0],
});

export default connect(mapState, { updateUser, closePromoModal })(
  PromoCodeModal
);

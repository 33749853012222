import { connect } from 'react-redux';
import { logout, verifyRole } from '../redux/auth/auth.actions';
import { ApplicationState } from '../redux/types';
import IndexRoutes from './IndexRoutes';

const mapState = ({
  app,
  auth,
  router,
  user,
  children,
  stripe,
}: ApplicationState) => ({
  app,
  auth,
  router,
  user,
  children,
  stripe,
});

export default connect(mapState, { verifyRole, logout })(IndexRoutes);

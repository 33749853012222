import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

export function getUrlPrefix() {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
    case 'staging':
    case 'production':
    default:
      return 'us-central1-moneyprep-dev';
  }
}

const config = Object.freeze({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY || '',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || '',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '',
});

const app = initializeApp(config!);

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_CLIENT!),
  isTokenAutoRefreshEnabled: true,
});

const db = getFirestore(appCheck.app);
export const auth = getAuth(appCheck.app);
export const functions = getFunctions(appCheck.app);

export default db;

import { connect } from 'react-redux';
import { ApplicationState } from '../../../redux/types';
import { linkChild } from '../../../redux/child/child.actions';
import LinkChildForm from './LinkChildForm';

const mapState = ({ app, children }: ApplicationState) => ({
  app,
  childrenUsers: children,
});

export default connect(mapState, { linkChild })(LinkChildForm);

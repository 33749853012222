import 'firebase/functions';
import { collection, query, where, getDocs } from 'firebase/firestore';
import db from '../../config/firebase';
import { ChallengeResult } from '../../types/challenge';

const challengeResultsCollection = collection(db, 'challengeResults');

// eslint-disable-next-line import/prefer-default-export
export const getChallengeResults = async (
  playerId: string
): Promise<ChallengeResult[]> => {
  const queryData = query(
    challengeResultsCollection,
    where('playerId', '==', playerId)
  );
  const queryResult = await getDocs(queryData);
  return queryResult.docs.map(
    (doc) => ({ id: doc.id, ...doc.data() } as ChallengeResult)
  );
};

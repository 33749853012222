import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import useStyles from './styles';

interface LoadingButtonProps {
  title: string;
  isSubmitting: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isDisabled?: boolean;
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  title,
  isSubmitting,
  isDisabled,
  onClick,
}) => {
  const classes = useStyles();
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => onClick && onClick(event);

  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      disabled={isDisabled || isSubmitting}
      onClick={handleClick}
      className={classes.root}>
      {isSubmitting ? (
        <CircularProgress size="1.5rem" className={classes.loader} />
      ) : (
        title
      )}
    </Button>
  );
};

export default LoadingButton;
